import { ReqIv } from "./encryptUtils"

const iv = new TextEncoder().encode(ReqIv)

//  RSA服务端公钥
const serverPubkey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQClC2MafxQ8Gn1qbqYuCttUoSUMtcykcYi3IZYYDvK4MY7MtHtXzrvEcJDeXW9aSQtvoVEtLB1fQBj41ScHNF5oWwNy9anms86xx8ry1eP5uHAT+MrW9mVrLkftB9EktHblQwUgTs5dRhTXAF3v12SkmOV1UBr4AfFYb9lpsximbQIDAQAB`

// let key
const importKeyFun = async (keyString = 'HdBbAxyfM6vuU5wq') => {
  const keytem = new TextEncoder().encode(keyString);
  const key = await window.crypto.subtle.importKey(
    "raw",
    keytem,
    "AES-CBC",
    false,
    ["encrypt", "decrypt"]
  );
  return key
}

//加密
const encryptMessage = async (word, key) => {
  const enc = new TextEncoder();
  let encoded = enc.encode(word);
  let ciphertext = await window.crypto.subtle.encrypt(
    {
      name: "AES-CBC",
      iv
    },
    key,
    encoded
  );
  return btoa(String.fromCharCode(...new Uint8Array(ciphertext)))
}

//加密RSA服务端公钥
const encryPublicKey = async () => {
  const keys = await importKeyFun()
  await encryptMessage(serverPubkey, keys)
}

//解密
const decryptMessage = async (ciphertext, key) => {
  const encryptedData = Uint8Array.from(atob(ciphertext ? ciphertext : ''), c => c.charCodeAt(0));
  let decrypted = await window.crypto.subtle.decrypt(
    {
      name: "AES-CBC",
      iv
    },
    key,
    encryptedData
  );
  let dec = new TextDecoder();
  return dec.decode(decrypted);
}

export {
  encryptMessage,
  decryptMessage,
  encryPublicKey,
  importKeyFun,
}