//使用到的字典codes
const codeList = [
  '1026',
  '1028',
  '1029',
  '1031',
  '1032',
  '1042',
  '1045',
  '1047',
  '1050',
  '1060',
  '1062',
  '1064',
  '1075',
  '1077',
]
export { codeList }
