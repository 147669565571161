import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import { store } from '@/pinia/store'
import { isApp, isWechat, goLogin, loadScript, locationPush, isWeiXin } from '@/utils'

// 路由信息
const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/index',
    name: 'homeModule',
    component: () => import('../views/homeModule/index.vue'),
    meta: {
      title: '往约',
      isNotAuth: true,
      keepAlive: true,
      TabBarShow: true
    },
  },
  // {
  //   path: '/index',
  //   name: 'indexModule',
  //   component: () => import('../views/indexModule/index.vue'),
  //   meta: {
  //     title: '往约',
  //     isNotAuth: true,
  //     // keepAlive: true,
  //     TabBarShow: true
  //   },
  // },
  {
    path: '/homeModule/addressSelection/index',
    name: 'addressSelection',
    component: () => import('../views/homeModule/addressSelection/index.vue'),
    meta: {
      title: '选择服务地址',
      isNotAuth: true
    },
  },
  {
    path: '/homeModule/addressSelection/citySelection',
    name: 'citySelection',
    component: () =>
      import('../views/homeModule/addressSelection/citySelection.vue'),
    meta: {
      title: '城市选择',
      isNotAuth: true
    },
  },
  {
    //删除
    path: '/playVideoOrViewImage',
    name: 'playVideoOrViewImage',
    component: () =>
      import('../views/homeModule/playVideoOrViewImage/index.vue'),
    meta: {
      title: '',
      isNotAuth: true,
    },
  },
  //orderModule
  {
    path: '/orderModule/myOrders/index',
    name: 'myOrders',
    component: () => import('../views/orderModule/myOrders/index.vue'),
    meta: {
      title: '订单列表',
      keepAlive: true,
      TabBarShow: true,
    },
  },
  {
    path: '/orderModule/myOrders/detail/index',
    name: 'orderDetail',
    component: () => import('../views/orderModule/myOrders/detail/index.vue'),
    meta: {
      title: '订单详情',
    },
  },
  {
    path: '/orderModule/myOrders/editOrder/index',
    name: 'editOrder',
    component: () =>
      import('../views/orderModule/myOrders/editOrder/index.vue'),
    meta: {
      title: '修改订单',
    },
  },
  {
    path: '/orderModule/bookingOrder/index',
    name: 'bookingOrder',
    component: () => import('../views/orderModule/bookingOrder/index.vue'),
    meta: {
      title: '预约下单',
      keepAlive: true,
    },
  },
  {
    path: '/orderModule/orderTracking/index',
    name: 'orderTracking',
    component: () => import('../views/orderModule/orderTracking/index.vue'),
    meta: {
      title: '订单跟踪',
    },
  },
  {
    path: '/orderModule/payState/index',
    name: 'payState',
    component: () => import('../views/orderModule/payState/index.vue'),
    meta: {
      title: '支付结果',
    },
  },
  {
    path: '/orderModule/payState/indexNew',
    name: 'indexNew',
    component: () => import('../views/orderModule/payState/indexNew.vue'),
    meta: {
      title: '支付确认',
    },
  },
  {
    path: '/orderModule/payState/success',
    name: 'orderPayState',
    component: () => import('../views/orderModule/payState/success.vue'),
    meta: {
      title: '支付成功',
      isNotAuth: true,
    },
  },
  {
    path: '/orderModule/applyRefund/index',
    name: 'applyRefund',
    component: () => import('../views/orderModule/applyRefund/index.vue'),
    meta: {
      title: '申请退款',
    },
  },
  {
    path: '/orderModule/addOrder/index',
    name: 'addOrder',
    component: () => import('../views/orderModule/addOrder/index.vue'),
    meta: {
      title: '打赏',
    },
  },
  {
    path: '/orderModule/addClock/index',
    name: 'addClock',
    component: () => import('../views/orderModule/addClock/index.vue'),
    meta: {
      title: '加钟',
    },
  },
  {
    path: '/orderModule/myOrders/refundReason/index',
    name: 'refundReason',
    component: () =>
      import('../views/orderModule/myOrders/refundReason/index.vue'),
    meta: {
      title: '拒绝原因',
    },
  },
  {
    path: '/orderModule/refundDetail/index',
    name: 'refundDetail',
    component: () => import('../views/orderModule/refundDetail/index.vue'),
    meta: {
      title: '退款详情',
    },
  },
  {
    path: '/orderModule/refundDetail/consultHistory',
    name: 'consultHistory',
    component: () =>
      import('../views/orderModule/refundDetail/consultHistory.vue'),
    meta: {
      title: '协商历史',
    },
  },
  {
    path: '/orderModule/serviceIn/index',
    name: 'serviceIn',
    component: () => import('../views/orderModule/serviceIn/index.vue'),
    meta: {
      title: '提交申请',
    },
  },
  // productList
  {
    path: '/productModule/productList/index',
    name: 'productList',
    component: () =>
      import('../views/productModule/productList/index.vue'),
    meta: {
      title: '全部套餐',
      keepAlive: true,
      isNotAuth: true,
    },
  },
  // videoModule
  {
    path: '/videoModule/index',
    name: 'videoModule',
    component: () =>
      import('../views/videoModule/videoIndex/index.vue'),
    meta: {
      title: '视频',
      keepAlive: true,
      isNotAuth: true,
      TabBarShow: true
    },
  },
  //technicianModule
  {
    path: '/technicianModule/technicianList/index',
    name: 'technicianList',
    component: () =>
      import('../views/technicianModule/technicianList/index.vue'),
    meta: {
      title: '技师',
      keepAlive: true,
      isNotAuth: true,
      TabBarShow: true
    },
  },
  {
    path: '/technicianModule/chooseTechnician/index',
    name: 'chooseTechnician',
    component: () =>
      import('../views/technicianModule/chooseTechnician/index.vue'),
    meta: {
      title: '选择技师',
      keepAlive: true,
      isNotAuth: true,
    },
  },
  {
    path: '/technicianModule/technicianDetail/index',
    name: 'technicianDetail',
    component: () =>
      import('../views/technicianModule/technicianDetail/index.vue'),
    meta: {
      title: '技师详情',
      isNotAuth: true,
    },
  },
  {
    path: '/technicianModule/techProfile/index',
    name: 'techProfile',
    component: () => import('../views/technicianModule/techProfile/index.vue'),
    meta: {
      title: '技师主页',
      keepAlive: true,
      isNotAuth: true,
    },
  },
  {
    path: '/technicianModule/techQualification/index',
    name: 'techQualification',
    component: () =>
      import('../views/technicianModule/techQualification/index.vue'),
    meta: {
      title: '资格认证',
      isNotAuth: true,
    },
  },
  {
    path: '/technicianModule/technicianPosition/index',
    name: 'technicianPosition',
    component: () =>
      import('../views/technicianModule/technicianPosition/index.vue'),
    meta: {
      title: '技师位置',
      isNotAuth: true,
    },
  },
  {
    path: '/technicianModule/techAgent/index',
    name: 'techAgent',
    component: () => import('../views/technicianModule/techAgent/index.vue'),
    meta: {
      title: '商家信息',
      isNotAuth: true
    },
  },
  {
    path: '/technicianModule/technicianDetail/unAttentionTechList',
    name: 'unAttentionTechList',
    component: () => import('../views/technicianModule/technicianDetail/unAttentionTechList.vue'),
    meta: {
      title: '关注技师',
      isNotAuth: true,
      keepAlive: true,
    },
  },
  {
    path: '/technicianModule/technicianDetail/techCert',
    name: 'techCert',
    component: () => import('../views/technicianModule/techCert/index.vue'),
    meta: {
      title: '',
      isNotAuth: true,
    },
  },
  {
    path: '/technicianModule/topicPage/index',
    name: 'topicPage',
    component: () => import('../views/technicianModule/topicPage/index.vue'),
    meta: {
      title: '上门按摩就选往约',
      isNotAuth: true,
      keepAlive: true,
    },
  },
  //trendsModule 400
  {
    path: '/trendsModule/trends/index',
    name: 'trendsList',
    component: () => import('../views/trendsModule/trends/index.vue'),
    meta: {
      title: '发现',
      keepAlive: true,
      isNotAuth: true
    },
  },
  {
    path: '/trendsModule/topicList/index',
    name: 'topicList',
    component: () => import('../views/trendsModule/topicList/index.vue'),
    meta: {
      title: '',
      isNotAuth: true,
    },
  },
  {
    path: '/trendsModule/trendsDetail/index',
    name: 'trendsDetail',
    component: () => import('../views/trendsModule/trendsDetail/index.vue'),
    meta: {
      title: '',
      isNotAuth: true,
    },
  },
  //productModule
  {
    path: '/productModule/productDetail/index',
    name: 'productDetail',
    component: () => import('../views/productModule/productDetail/index.vue'),
    meta: {
      title: '套餐',
      isNotAuth: true,
    },
  },
  //myModule
  {
    path: '/myModule/home/index',
    name: 'myModuleHome',
    component: () => import('../views/myModule/home/index.vue'),
    meta: {
      title: '我的',
      TabBarShow: true,
    },
  },
  {
    path: '/myModule/login/index',
    name: 'login',
    component: () => import('../views/myModule/login/index.vue'),
    meta: {
      title: '登录',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/login/middle',
    name: 'middle',
    component: () => import('../views/myModule/login/middle.vue'),
    meta: {
      title: '',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/login/resetOpenid',
    name: 'resetOpenid',
    component: () => import('../views/myModule/login/resetOpenid.vue'),
    meta: {
      title: '',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/login/stopLogin',
    name: 'stopLogin',
    component: () => import('../views/myModule/login/stopLogin.vue'),
    meta: {
      title: '',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/login/applyOpenLogin',
    name: 'applyOpenLogin',
    component: () => import('../views/myModule/login/applyOpenLogin.vue'),
    meta: {
      title: '申请解封',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/mySetting/index',
    name: 'mySettingIndex',
    component: () => import('../views/myModule/mySetting/index.vue'),
    meta: {
      title: '设置',
    },
  },
  {
    path: '/myModule/myPersonData/index',
    name: 'myPersonDataIndex',
    component: () => import('../views/myModule/myPersonData/index.vue'),
    meta: {
      title: '个人中心',
    },
  },
  {
    path: '/myModule/myPersonData/cropperImage',
    name: 'cropperImage',
    component: () => import('../views/myModule/myPersonData/cropperImage.vue'),
    meta: {
      title: '头像上传',
    },
  },
  {
    path: '/myModule/myAttention/index',
    name: 'myAttention',
    component: () => import('../views/myModule/myAttention/index.vue'),
    meta: {
      title: '我的关注',
    },
  },
  {
    path: '/myModule/myCollection/index',
    name: 'myCollection',
    component: () => import('../views/myModule/myCollection/index.vue'),
    meta: {
      title: '我的收藏',
    },
  },
  {
    path: '/myModule/myEvaluation/index',
    name: 'myEvaluation',
    component: () => import('../views/myModule/myEvaluation/index.vue'),
    meta: {
      title: '我的评价',
    },
  },
  {
    path: '/myModule/unlikeTech/index',
    name: 'unlikeTech',
    component: () => import('../views/myModule/unlikeTech/index.vue'),
    meta: {
      title: '屏蔽列表',
    },
  },
  {
    path: '/myModule/myWallet/index',
    name: 'myWallet',
    component: () => import('../views/myModule/myWallet/index.vue'),
    meta: {
      title: '我的钱包',
    },
  },
  {
    path: '/myModule/myWallet/list',
    name: 'myWalletList',
    component: () => import('../views/myModule/myWallet/list.vue'),
    meta: {
      title: '我的钱包',
    },
  },
  {
    path: '/myModule/vipCard/index',
    name: 'vipCard',
    component: () => import('../views/myModule/vipCard/index.vue'),
    meta: {
      title: 'VIP会员卡',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/vipCard/wednesdayMemberDay',
    name: 'wednesdayMemberDay',
    component: () => import('../views/myModule/vipCard/wednesdayMemberDay.vue'),
    meta: {
      title: '周三会员日',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/messageCenter/IM/index',
    name: 'imMessage',
    component: () => import('../views/myModule/messageCenter/IM/index.vue'),
    meta: {
      title: '在线消息',
    },
  },
  {
    path: '/myModule/messageCenter/YSF/index',
    name: 'ysfMessage',
    component: () => import('../views/myModule/messageCenter/YSF/index.vue'),
    meta: {
      title: '在线客服',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/feedback/index',
    name: 'feedback',
    component: () => import('../views/myModule/feedback/index.vue'),
    meta: {
      title: '反馈建议',
    },
  },
  {
    path: '/myModule/feedback/record/index',
    name: 'feedbackRecord',
    component: () => import('../views/myModule/feedback/record/index.vue'),
    meta: {
      title: '反馈记录',
    },
  },
  {
    path: '/myModule/feedback/detail/index',
    name: 'feedbackDetail',
    component: () => import('../views/myModule/feedback/detail/index.vue'),
    meta: {
      title: '反馈详情',
    },
  },
  {
    path: '/myModule/report/index',
    name: 'report',
    component: () => import('../views/myModule/report/index.vue'),
    meta: {
      title: '投诉举报',
    },
  },
  {
    path: '/myModule/report/record/index',
    name: 'reportRecord',
    component: () => import('../views/myModule/report/record/index.vue'),
    meta: {
      title: '举报记录',
    },
  },
  {
    path: '/myModule/report/detail/index',
    name: 'reportDetail',
    component: () => import('../views/myModule/report/detail/index.vue'),
    meta: {
      title: '举报详情',
    },
  },
  {
    path: '/myModule/myCoupon/index',
    name: 'myCoupon',
    component: () => import('../views/myModule/myCoupon/index.vue'),
    meta: {
      title: '我的优惠券',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/myCoupon/dailySpecial',
    name: 'dailySpecial',
    component: () => import('../views/myModule/myCoupon/dailySpecial.vue'),
    meta: {
      title: '超级满减',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/myCoupon/history',
    name: 'historyCoupon',
    component: () => import('../views/myModule/myCoupon/history.vue'),
    meta: {
      title: '历史优惠券',
    },
  },

  {
    path: '/myModule/myRewardRecord/index',
    name: 'myRewardRecord',
    component: () => import('../views/myModule/myRewardRecord/index.vue'),
    meta: {
      title: '打赏记录',
    },
  },
  {
    path: '/myModule/aboutUs/index',
    name: 'aboutUs',
    component: () => import('../views/myModule/aboutUs/index.vue'),
    meta: {
      title: '关于我们',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/myAddress/index',
    name: 'myAddress',
    component: () => import('../views/myModule/myAddress/index.vue'),
    meta: {
      title: '地址管理',
    },
  },
  {
    path: '/myModule/myAddress/addOrUpdate',
    name: 'addOrUpdateAddress',
    component: () => import('../views/myModule/myAddress/addOrUpdate.vue'),
    meta: {
      title: '添加地址',
    },
  },
  {
    path: '/myModule/myAddress/selectMapAddress',
    name: 'selectMapAddress',
    component: () => import('../views/myModule/myAddress/selectMapAddress.vue'),
    meta: {
      title: '选择服务地址',
    },
  },
  {
    path: '/myModule/cancelAccount/index',
    name: 'cancelAccount',
    component: () => import('../views/myModule/cancelAccount/index.vue'),
    meta: {
      title: '注销账号',
    },
  },
  {
    path: '/myModule/cancelAccount/cancelReason',
    name: 'cancelReason',
    component: () => import('../views/myModule/cancelAccount/cancelReason.vue'),
    meta: {
      title: '注销原因',
    },
  },
  {
    path: '/myModule/cancelAccount/success',
    name: 'cancelAccountSuccess',
    component: () => import('../views/myModule/cancelAccount/success.vue'),
    meta: {
      title: '注销成功',
    },
  },
  {
    path: '/myModule/cancelAccount/fail',
    name: 'cancelAccountFail',
    component: () => import('../views/myModule/cancelAccount/fail.vue'),
    meta: {
      title: '注销失败',
    },
  },
  {
    path: '/myModule/login/loginForThird',
    name: 'loginForThird',
    component: () => import('../views/myModule/login/loginForThird.vue'),
    meta: {
      title: '', //第三方登录
      isNotAuth: true
    },
  },
  // {
  //   path: '/myModule/orderProblem/index',
  //   name: 'orderProblem',
  //   component: () => import('../views/myModule/orderProblem/index.vue'),
  //   meta: {
  //     title: '帮助中心',
  //   },
  // },
  {
    path: '/myModule/feedback/addMoreInfo/index',
    name: 'addFeedbackMoreInfo',
    component: () => import('../views/myModule/feedback/addMoreInfo/index.vue'),
    meta: {
      title: '补充说明',
    },
  },
  {
    path: '/myModule/report/addMoreInfo/index',
    name: 'addReportInfo',
    component: () => import('../views/myModule/report/addMoreInfo/index.vue'),
    meta: {
      title: '补充说明',
    },
  },
  {
    path: '/myModule/attendWechat/index',
    name: 'attendWechat',
    component: () => import('../views/myModule/attendWechat/index.vue'),
    meta: {
      title: '关注公众号',
    },
  },
  {
    path: '/myModule/cancelAccount/cancelByCode',
    name: 'cancelByCode',
    component: () => import('../views/myModule/cancelAccount/cancelByCode.vue'),
    meta: {
      title: '',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/myPersonData/changePhone',
    name: 'changePhone',
    component: () => import('../views/myModule/myPersonData/changePhone.vue'),
    meta: {
      title: '',
    },
  },
  {
    path: '/myModule/myPersonData/getPhoneCode',
    name: 'getPhoneCode',
    component: () => import('../views/myModule/myPersonData/getPhoneCode.vue'),
    meta: {
      title: '',
    },
  },
  {
    path: '/myModule/growthCenter/index',
    name: 'growthCenter',
    component: () => import('../views/myModule/growthCenter/index.vue'),
    meta: {
      title: '成长中心',
    },
  },
  {
    path: '/myModule/growthCenter/equityDetails',
    name: 'equityDetails',
    component: () => import('../views/myModule/growthCenter/equityDetails.vue'),
    meta: {
      title: '权益详情',
    },
  },
  {
    path: '/myModule/growthCenter/levelDescription',
    name: 'levelDescription',
    component: () => import('../views/myModule/growthCenter/levelDescription.vue'),
    meta: {
      title: '等级说明',
      isNotAuth: true,
    },
  },
  {
    path: '/myModule/growthCenter/myGrowth',
    name: 'myGrowth',
    component: () => import('../views/myModule/growthCenter/myGrowth.vue'),
    meta: {
      title: '我的成长值',
    },
  },
  {
    path: '/myModule/growthCenter/growthDetail',
    name: 'growthDetail',
    component: () => import('../views/myModule/growthCenter/growthDetail.vue'),
    meta: {
      title: '成长值明细',
    },
  },
  {
    path: '/myModule/growthCenter/shareVipCard',
    name: 'shareVipCard',
    component: () => import('../views/myModule/growthCenter/shareVipCard.vue'),
    meta: {
      title: '会员卡分享',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/growthCenter/getVipCard',
    name: 'getVipCard',
    component: () => import('../views/myModule/growthCenter/getVipCard.vue'),
    meta: {
      title: '领取会员卡',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/login/techLogin',
    name: 'techLogin',
    component: () => import('../views/myModule/login/techLogin.vue'),
    meta: {
      title: '技师登录',
      isNotAuth: true
    },
  },
  //evaluationModule
  {
    path: '/evaluationModule/evaluation/index',
    name: 'evaluationList',
    component: () => import('../views/evaluationModule/evaluation/index.vue'),
    meta: {
      title: '用户评价',
    },
  },
  {
    path: '/evaluationModule/addEvaluation/index',
    name: 'addEvaluation',
    component: () =>
      import('../views/evaluationModule/addEvaluation/index.vue'),
    meta: {
      title: '评价',
    },
  },
  {
    path: '/evaluationModule/report/index',
    name: 'reportEvaluation',
    component: () => import('../views/evaluationModule/report/index.vue'),
    meta: {
      title: '举报评价',
    },
  },
  {
    path: '/evaluationModule/report/addReport',
    name: 'addEvaluationReport',
    component: () => import('../views/evaluationModule/report/addReport.vue'),
    meta: {
      title: '举报评价',
    },
  },
  {
    path: '/evaluationModule/foldingEvaluation/index',
    name: 'foldingEvaluation',
    component: () => import('../views/evaluationModule/foldingEvaluation/index.vue'),
    meta: {
      title: '折叠评价',
    },
  },
  //shopModule
  {
    path: '/shopModule/shopList/index',
    name: 'shopList',
    component: () => import('../views/shopModule/shopList/index.vue'),
    meta: {
      title: '精选到店',
      isNotAuth: true
    },
  },
  {
    path: '/shopModule/shopDetail/index',
    name: 'shopDetail',
    component: () => import('../views/shopModule/shopDetail/index.vue'),
    meta: {
      title: '',
      isNotAuth: true
    },
  },
  {
    path: '/shopModule/shopOrder/index',
    name: 'shopOrder',
    component: () => import('../views/shopModule/shopOrder/index.vue'),
    meta: {
      title: '预约下单',
    },
  },
  {
    path: '/shopModule/shopOrder/index',
    name: 'shopOrder',
    component: () => import('../views/shopModule/shopOrder/index.vue'),
    meta: {
      title: '预约下单',
    },
  },
  {
    path: '/shopModule/shopOrder/detail',
    name: 'shopOrderDetail',
    component: () => import('../views/shopModule/shopOrder/detail.vue'),
    meta: {
      title: '订单详情',
    },
  },
  {
    path: '/shopModule/chooseTech/index',
    name: 'chooseTech',
    component: () => import('../views/shopModule/chooseTech/index.vue'),
    meta: {
      title: '技师',
      isNotAuth: true
    },
  },
  //rule  900
  {
    path: '/pages/rule/refundProcess',
    name: 'refundProcess',
    component: () => import('../views/pages/rule/refundProcess.vue'),
    meta: {
      title: '退款说明',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/vipcardAgreement',
    name: 'vipcardAgreement',
    component: () => import('../views/pages/rule/vipcardAgreement.vue'),
    meta: {
      title: '会员卡协议',
    },
  },
  {
    path: '/pages/rule/userGuide',
    name: 'userGuide',
    component: () => import('../views/pages/rule/userGuide.vue'),
    meta: {
      title: '操作流程',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/dutyAvowal',
    name: 'dutyAvowal',
    component: () => import('../views/pages/rule/dutyAvowal.vue'),
    meta: {
      title: '责任声明',
      isNotAuth: true
    },
  },
  {
    path: '/pages/helpDoc/detail',
    name: 'helpDocDetail',
    component: () => import('../views/pages/helpDoc/detail.vue'),
    meta: {
      title: '详情',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/userProxy',
    name: 'userProxy',
    component: () => import('../views/pages/rule/userProxy.vue'),
    meta: {
      title: '用户协议',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('../views/pages/rule/privacyPolicy.vue'),
    meta: {
      title: '隐私政策',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/phoneProtect',
    name: 'phoneProtect',
    component: () => import('../views/pages/rule/phoneProtect.vue'),
    meta: {
      title: '号码保护',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/appointNotice',
    name: 'appointNotice',
    component: () => import('../views/pages/rule/appointNotice.vue'),
    meta: {
      title: '预约须知',
      isNotAuth: true,
    },
  },
  {
    path: '/pages/point/index',
    name: 'point',
    component: () => import('../views/pages/point/index.vue'),
    meta: {
      title: '休闲娱乐',
    },
  },
  {
    path: '/pages/point/signIn',
    name: 'signIn',
    component: () => import('../views/pages/point/signIn.vue'),
    meta: {
      title: '签到',
    },
  },
  {
    path: '/pages/point/luckLarge',
    name: 'luckLarge',
    component: () => import('../views/pages/point/luckLarge.vue'),
    meta: {
      title: '幸运大转盘',
    },
  },
  {
    path: '/pages/point/detail',
    name: 'pointDetail',
    component: () => import('../views/pages/point/detail.vue'),
    meta: {
      title: '积分明细',
    },
  },
  {
    path: '/pages/rule/productDesc',
    name: 'productDesc',
    component: () => import('../views/pages/rule/productDesc.vue'),
    meta: {
      title: '订购须知',
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/friend',
    name: 'inviteFriend',
    component: () => import('../views/pages/invite/friend.vue'),
    meta: {
      title: '邀请好友',
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/friendList',
    name: 'inviteFriendList',
    component: () => import('../views/pages/invite/friendList.vue'),
    meta: {
      title: '邀请好友列表',
      index: 916,
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/technician',
    name: 'inviteTechnician',
    component: () => import('../views/pages/invite/technician.vue'),
    meta: {
      title: '邀请技师',
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/technicianList',
    name: 'inviteTechnicianList',
    component: () => import('../views/pages/invite/technicianList.vue'),
    meta: {
      title: '邀请技师列表',
      isNotAuth: true,
    },
  },
  {
    path: '/pages/invite/poster',
    name: 'poster',
    component: () => import('../views/pages/invite/poster.vue'),
    meta: {
      title: '邀请海报',
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/partner',
    name: 'partnerInviteFriends',
    component: () => import('../views/pages/invite/partner.vue'),
    meta: {
      title: '邀请技师',
      keepAlive: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/techRegister',
    name: 'techRegister',
    component: () => import('../views/pages/invite/techRegister.vue'),
    meta: {
      title: '往约',
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/inviteCode',
    name: 'inviteCode',
    component: () => import('../views/pages/invite/inviteCode.vue'),
    meta: {
      title: '邀请二维码',
      isNotAuth: true
    },
  },
  {
    path: '/pages/recruit/index',
    name: 'recruit',
    component: () => import('../views/pages/recruit/Index.vue'),
    meta: {
      title: '招聘合作',
      showAppBtn: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/recruit/masseur',
    name: 'masseur',
    component: () => import('../views/pages/recruit/Masseur.vue'),
    meta: {
      title: '应聘技师',
      showAppBtn: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/recruit/partner',
    name: 'partner',
    component: () => import('../views/pages/recruit/Partner.vue'),
    meta: {
      title: '城市合伙人',
      showAppBtn: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/recruit/storeSurvey',
    name: 'storeSurvey',
    component: () => import('../views/pages/recruit/StoreSurvey.vue'),
    meta: {
      title: '门店入驻',
      showAppBtn: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/managePrivacyPolicy',
    name: 'managePrivacyPolicy',
    component: () => import('../views/pages/rule/managePrivacyPolicy.vue'),
    meta: {
      title: '管理版隐私政策',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/question',
    name: 'question',
    component: () => import('../views/pages/rule/question.vue'),
    meta: {
      title: '常见问题',
      isNotAuth: true
    },
  },
  {
    path: '/pages/share/index',
    name: 'share',
    component: () => import('../views/pages/share/index.vue'),
    meta: {
      title: '扫码关注',
      isNotAuth: true
    },
  },
  {
    path: '/pages/settleIn/index',
    name: 'settleIn',
    component: () => import('../views/pages/settleIn/index.vue'),
    meta: {
      title: '技师招募',
      isNotAuth: true
    },
  },
  {
    path: '/pages/survey/user',
    name: 'userSurvey',
    component: () => import('../views/pages/survey/user.vue'),
    meta: {
      title: '用户问卷调查',
      isNotAuth: true
    },
  },
  {
    path: '/pages/survey/reBack',
    name: 'reBack',
    component: () => import('../views/pages/survey/reBack.vue'),
    meta: {
      title: '问卷调查',
      isNotAuth: true
    },
  },
  {
    path: '/pages/updateDescription/index',
    name: 'updateDescription',
    component: () => import('../views/pages/updateDescription/index.vue'),
    meta: {
      title: '升级说明',
      isNotAuth: true
    },
  },
  {
    path: '/pages/healthKnowledge/index',
    name: 'healthKnowledge',
    component: () => import('../views/pages/healthKnowledge/index.vue'),
    meta: {
      title: '养生知识',
      isNotAuth: true
    },
  },
  {
    path: '/pages/vote/index',
    name: 'vote',
    component: () => import('../views/pages/vote/index.vue'),
    meta: {
      title: '技师调度站',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/serviceGuide',
    name: 'serviceGuide',
    component: () => import('../views/pages/rule/serviceGuide.vue'),
    meta: {
      title: '技师端操作流程',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/appDownload',
    name: 'appDownload',
    component: () => import('../views/pages/rule/appDownload.vue'),
    meta: {
      title: 'App下载',
      isNotAuth: true
    },
  },
  {
    path: '/pages/sharepay/index',
    name: 'sharepay',
    component: () => import('../views/pages/sharepay/index.vue'),
    meta: {
      title: '代付',
      isNotAuth: true
    },
  },
  {
    path: '/pages/sharepay/paySuccess',
    name: 'paySuccess',
    component: () => import('../views/pages/sharepay/paySuccess.vue'),
    meta: {
      title: '代付成功',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/orderAgainstGuide',
    name: 'orderAgainstGuide',
    component: () => import('../views/pages/rule/orderAgainstGuide.vue'),
    meta: {
      title: '举报流程',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/agreementSign',
    name: 'agreementSign',
    component: () => import('../views/pages/rule/agreementSign.vue'),
    meta: {
      title: '电子合同',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/serviceSummaryIntroductions',
    name: 'serviceSummaryIntroductions',
    component: () => import('../views/pages/rule/serviceSummaryIntroductions.vue'),
    meta: {
      title: '数据统计说明',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/serviceNotWantBeSee',
    name: 'serviceNotWantBeSee',
    component: () => import('../views/pages/rule/serviceNotWantBeSee.vue'),
    meta: {
      title: '不想被ta看到',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/thirdDescription',
    name: 'thirdDescription',
    component: () => import('../views/pages/rule/thirdDescription.vue'),
    meta: {
      title: '三方平台规则说明',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/agreementPartner',
    name: 'agreementPartner',
    component: () => import('../views/pages/rule/agreementPartner.vue'),
    meta: {
      title: '灵活就业合作协议',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/orderReportGuide',
    name: 'orderReportGuide',
    component: () => import('../views/pages/rule/orderReportGuide.vue'),
    meta: {
      title: '订单报备记录',
      isNotAuth: true
    },
  },
  {
    path: '/pages/bindWechat/index',
    name: 'bindWechat',
    component: () => import('../views/pages/bindWechat/index.vue'),
    meta: {
      title: '绑定微信',
      isNotAuth: true
    },
  },
  {
    path: '/pages/bindWechat/tipsForBind',
    name: 'tipsForBind',
    component: () => import('../views/pages/bindWechat/tipsForBind.vue'),
    meta: {
      title: '绑定提示',
      isNotAuth: true
    },
  },
  {
    path: '/pages/bindWechat/bindSuccess',
    name: 'bindSuccess',
    component: () => import('../views/pages/bindWechat/bindSuccess.vue'),
    meta: {
      title: '绑定成功',
      isNotAuth: true
    },
  },
  {
    path: '/pages/rule/joiningGuidelines',
    name: 'joiningGuidelines',
    component: () => import('../views/pages/rule/joiningGuidelines.vue'),
    meta: {
      title: '技师入驻指南',
      isNotAuth: true
    },
  },
  {
    path: '/pages/tencentNews/index',
    name: 'tencentNews',
    component: () => import('../views/pages/tencentNews/index.vue'),
    meta: {
      title: '往约',
      isNotAuth: true
    },
  },
  {
    path: '/pages/activity/index',
    name: 'activity',
    component: () => import('../views/pages/activity/index.vue'),
    meta: {
      title: '测试落地页',
      isNotAuth: true
    },
  },
  {
    path: '/pages/shareNowLocation/index',
    name: 'shareNowLocation',
    component: () => import('../views/pages/shareNowLocation/index.vue'),
    meta: {
      title: '实时定位',
      isNotAuth: true
    },
  },
  {
    path: '/pages/shareVideo/index',
    name: 'shareVideo',
    component: () => import('../views/pages/shareVideo/index.vue'),
    meta: {
      title: '',
      isNotAuth: true
    },
  },
  {
    path: '/pages/videoVcPage/index',
    name: 'videoVcPage',
    component: () => import('@/views/videoModule/videoVcPage/index.vue'),
    meta: {
      title: '',
      isNotAuth: true
    },
  },
  {
    path: '/technicianModule/heartBeat/index',
    name: 'heartBeat',
    component: () => import('../views/technicianModule/heartBeat/index.vue'),
    meta: {
      title: '心动模式',
      isNotAuth: true
    },
  },
  {
    path: '/pages/redEnvelope/index',
    name: 'redEnvelope',
    component: () => import('../views/pages/redEnvelope/index.vue'),
    meta: {
      title: '红包雨',
    },
  },
  {
    path: '/pages/wynh/index',
    name: 'wynh',
    component: () => import('../views/pages/wynh/2024/index.vue'),
    meta: {
      title: '往约年会报名',
      isNotAuth: true
    },
  },
  {
    path: '/pages/redEnvelope/indexShare',
    name: 'shareRedEnvelope',
    component: () => import('../views/pages/redEnvelope/indexShare.vue'),
    meta: {
      title: '红包雨',
      isNotAuth: true
    },
  },
  {
    path: '/pages/redEnvelope/indexNotice',
    name: 'indexNotice',
    component: () => import('../views/pages/redEnvelope/indexNotice.vue'),
    meta: {
      title: '红包雨预告页',
      isNotAuth: true
    },
  },
  {
    path: '/pages/goddessDay/index',
    name: 'goddessDay',
    component: () => import('../views/pages/goddessDay/index.vue'),
    meta: {
      title: '38宠爱女神节',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/footPrint/index',
    name: 'footPrint',
    component: () => import('../views/myModule/footPrint/index.vue'),
    meta: {
      title: '最近浏览',
      isNotAuth: true
    },
  },
  {
    path: '/pages/foolDay/index',
    name: 'foolDay',
    component: () => import('../views/pages/foolDay/index.vue'),
    meta: {
      title: '愚人节',
      isNotAuth: true
    },
  },
  {
    path: '/pages/staticPage/index',
    name: 'staticPage',
    component: () => import('../views/pages/staticPage/index.vue'),
    meta: {
      title: '静态资源通用页面',
      isNotAuth: true
    },
  },
]

// 导出路由
const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  console.log(234)
  console.log(to)
  const appDataStore = store()
  keepAliveInit(to, from, appDataStore);
  if (isWeiXin()) {
    document.title = '往约'
  } else {
    if ((to?.meta?.title as string)?.length) {
      document.title = to.meta.title.toString()
    }
  }
  if (!isApp()) {//红包雨入口的三个路由
    appDataStore.isInRedPack = false
    if (['/index', '/trendsModule/trends/index', '/myModule/home/index'].includes(to.path) && !Boolean(to.query.isFromRain)) {
      appDataStore.isInRedPack = true
    }
  }
  if (isApp()) {
    next()
  } else if (isWechat()) {
    // 公众号登录权限拦截
    next()
  } else {
    const isLogin = !!appDataStore.appData.userId;
    const { query: indexQuery } = appDataStore.sourcePage

    //用户未登录且页面未授权时
    if (!to.meta.isNotAuth && !isLogin) {
      appDataStore.sourcePage = { path: from.path, query: { ...from.query } }
      appDataStore.nextPage= { path: to.path, query: { ...to.query } }
      goLogin(from.query)
      return;
    }
    //用户授权登录后回退到登录页时跳转至首页
    if (isLogin && to.path == '/myModule/login/index' && from.path !== '/myModule/cancelAccount/cancelByCode') {
      if (from.query?.channelCode && from.query?.channelCode === 'jw') {
        locationPush('/index', { ...indexQuery, channelCode: 'jw' })
      } else {
        locationPush('/index', indexQuery)
      }
      // next('/index', { ...from.query })
      return
    }
    //绑定手机号页面返回登录页面拦截
    if (from.path == '/myModule/cancelAccount/cancelByCode' && to.path == '/myModule/login/index' && to.query.code) {
      console.log({ ...from.query, code: '' })
      goLogin({ ...from.query, code: '' })
      // next('/myModule/login/index',{})
      return
    }
    //登录页未登录返回时跳转至首页
    if (to.path == '/myModule/login/middle' && from.path == '/myModule/login/index') {
      // next('/index', { ...from.query })
      locationPush('/index', indexQuery)
      return
    }
    if (from.path !== '/myModule/login/middle' && from.path !== '/' && from.path !== '/index' && from.path !== '/myModule/cancelAccount/cancelByCode' && to.path == '/myModule/login/index') {
      appDataStore.sourcePage = { path: from.path, query: { ...from.query } }
      next()
      return
    }
    next()
  }
})
//页面缓存初始化方法
const keepAliveInit = async (to, from, appDataStore) => {
  const tabbarArr = ["homeModule", "technicianList", "trendsList", "myOrders", "myModuleHome"]//发现页缓存
  if (from.name === 'homeModule' && to.name === 'addressSelection') {
    deleteKeepAlive('homeModule', appDataStore)
  }
  //发现页到发现详情页缓存
  if (from.name === 'trendsList' && to.name !== 'trendsDetail') {
    deleteKeepAlive('trendsList', appDataStore)
  }
  if (from.name === 'homeModule' && to.name === 'messageCenter') {
    deleteKeepAlive('homeModule', appDataStore)
  }
  if (from.name === 'addressSelection' && to.name === 'homeModule') {
    deleteKeepAlive('homeModule', appDataStore)
  }
  if (from.name === 'technicianList' && to.name === 'addressSelection') {
    deleteKeepAlive('technicianList', appDataStore)
  }

  if (to.name === 'allCourse' && from.name !== 'courseDetail') {
    pushKeepAlive('allCourse', appDataStore)
  }
  if (from.name === 'allCourse' && to.name !== 'courseDetail') {
    deleteKeepAlive('allCourse', appDataStore)
  }
  if (to.name === 'bookingOrder' &&  !['myAddress', 'orderDetail', 'phoneProtect', 'appointNotice'].includes(from.name)) {
    pushKeepAlive('bookingOrder', appDataStore)
  }
  if (from.name === 'bookingOrder' && !['myAddress', 'orderDetail', 'phoneProtect', 'appointNotice'].includes(to.name)) {
    deleteKeepAlive('bookingOrder', appDataStore)
  }
  if (from.name === 'orderDetail' && to.name !== 'bookingOrder') {
    deleteKeepAlive('bookingOrder', appDataStore)
  }

  if (to.name === 'imMessage' &&  !['orderDetail', 'productDetail', 'technicianDetail', 'technicianList', 'productList', 'dailySpecial', 'vipCard', 'myWallet', 'growthCenter', 'myRewardRecord', 'myCoupon', 'reportRecord'].includes(from.name)) {
    pushKeepAlive('imMessage', appDataStore)
  }
  if (from.name === 'imMessage' && !['orderDetail', 'productDetail', 'technicianDetail', 'technicianList', 'productList', 'dailySpecial', 'vipCard', 'myWallet', 'growthCenter', 'myRewardRecord', 'myCoupon', 'reportRecord'].includes(to.name)) {
    const storeData = store()
    storeData.isFromMsc = false
    storeData.isImCusMessageClick = false
    deleteKeepAlive('imMessage', appDataStore)
  }

  // 4个tab页特殊处理
  if (tabbarArr.includes(from.name) && tabbarArr.includes(to.name)) {
    tabbarArr.forEach(item => {
      deleteKeepAlive(item, appDataStore)
    })
    pushKeepAlive(to.name, appDataStore)
  }

  if (to.meta.showAppBtn) {
    //xinstall 带下载APP按钮
    await loadScript('https://xinstall.top/xinstall/banner.js?xid=wjedcofb39')
  }
  const xbanner = document.getElementsByClassName('_xbanner')
  if (xbanner.length > 0) {
    xbanner[0].remove()
  }

}
//增加缓存页面
const pushKeepAlive = (name, appDataStore) => {
  const index = appDataStore.keepAliveInclude.findIndex((item) => item == name)
  if (index < 0) {
    appDataStore.keepAliveInclude.push(name)
  }
}
//删除缓存页面
const deleteKeepAlive = (name, appDataStore) => {
  const confirmOrderIndex = appDataStore.keepAliveInclude.findIndex(
    (item) => item == name
  )
  if (confirmOrderIndex > -1) {
    appDataStore.keepAliveInclude.splice(confirmOrderIndex, 1)
  }
}

router.onError((err) => {
  console.log(11111, err)
  setTimeout(() => {
    window.location.reload()
  }, 3000);
})

export default router
