<!-- 
    # 通用自定义loading弹窗组件
 -->
<template>
  <div v-if="appDataStore.isShowLoading" class="global_loading">
    <van-loading type="spinner" color="#ffa900" />
  </div>
</template>

<script setup>
import { store } from '../pinia/store'

const appDataStore = store()
</script>

<style lang="scss" scoped>
.global_loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
