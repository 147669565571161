import { store } from '../pinia/store'
import { useTimeoutFn, useIntervalFn, useThrottleFn, useDebounceFn } from '@vueuse/core'
import { showToast as vantShowToast, closeToast } from 'vant'
import { showDialog as vantShowDialog } from 'vant'
import { showNotify as vantShowNotify } from 'vant'
import { useGlobalZIndex } from './useGlobalZIndex'
import debounce from "lodash/debounce";
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'

const showToast = (
  message,
  position = 'middle',
  type = 'text',
  duration = 2000
) => {
  vantShowToast({ type, message, position, duration, 'z-index': useGlobalZIndex(), forbidClick: false })
}
const hideToast = () => {
  closeToast()
}
const showNotify = (message, type = 'success') => {
  //type:success,danger
  if (message) {
    vantShowNotify({ type, message })
  }
}
let loadingTimer
// 自定义loading弹窗
const showLoading = () => {
  clearTimeout(loadingTimer)
  const appDataStore = store()
  appDataStore.isShowLoading = true
  loadingTimer = setTimeout(() => {
    if (appDataStore.isShowLoading) {
      appDataStore.isShowLoading = false
      clearTimeout(loadingTimer)
    }
  }, 5000)
}
const hideLoading = () => {
  const appDataStore = store()
  appDataStore.isShowLoading = false
  clearTimeout(loadingTimer)
}

const showAlert = (
  message = '',
  title = '温馨提示',
  confirmButtonText = '确认',
  confirmButtonColor = '#ffa900'
) => {
  return new Promise((resolve, reject) => {
    vantShowDialog({
      title,
      message,
      confirmButtonText,
      confirmButtonColor,
    }).then(() => {
      resolve(true)
    })
  })
}

const showConfirm = (
  message = '',
  title = '温馨提示',
  confirmButtonText = '确认',
  cancelButtonText = '取消',
  confirmButtonColor = '#ffa900',
  showCancelButton = true
) => {
  return new Promise((resolve, reject) => {
    vantShowDialog({
      title,
      message,
      confirmButtonText,
      cancelButtonText,
      showCancelButton: showCancelButton,
      confirmButtonColor,
      // theme: 'round-button',
    })
      .then(() => {
        resolve(true)
      })
      .catch(() => {
        resolve(false)
      })
  })
}

const useSetTimeout = (fn, time = 1000) => {
  useTimeoutFn(fn, time)
}

const useSetInterval = (fn, time = 1000) => {
  useIntervalFn(fn, time)
}
// 节流函数，仅用于搜索类
const useGlobalThrottle = (callback, time = 3000) =>
  useThrottleFn(() => {
    callback()
  }, time)

let flag = true
const limitClick = (fn, time = 500) => {
  //防重复点击
  if (flag) {
    flag = false
    useTimeoutFn(() => {
      flag = true
    }, time)
    fn()
  }
}

// 防抖函数，
const useGlobalDebounce = (callback, time = 1000, maxWait = 5000) =>
  useDebounceFn(
    () => {
      callback();
    },
    time,
    { maxWait }
  );

const useLodashDebounce = (callback, time = 1000, maxWait = 5000) =>
  debounce(callback, time, { maxWait, leading: true, trailing: false });

export {
  showToast,
  hideToast,
  showLoading,
  hideLoading,
  showNotify,
  showAlert,
  showConfirm,
  useSetTimeout,
  useSetInterval,
  useGlobalThrottle,
  limitClick,
  useGlobalDebounce,
  useLodashDebounce,
}
