<!-- 全局图片组件，默认不开启懒加载 默认带loading效果 -->
<template>
  <van-image v-if="imgPath" :src="imgPath" :error-icon="errImg" fit="cover">
    <template v-slot:loading>
      <van-loading type="spinner" size="20" />
    </template>
  </van-image>
</template>

<script setup>
import { computed } from 'vue'
import { getImageUrl } from '@/utils'

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  type: {
    type: Number,
    default: 2 // 1:技师默认头像 2:其他所有的默认图 3:客户默认头像
  }
})

const errImg = getImageUrl('public', props.type === 1 ? 'tech_no.png' : (props.type === 3 ? 'user_no.png' : 'bg_noimg.png'))

const imgPath = computed(() => {
	// http开头的图片链接转化为https开头
	if (
		props.src.includes('http://oss') &&
		!props.src.includes('https') &&
		!props.src.includes('192.') &&
		!props.src.includes('127.') &&
		!props.src.includes('localhost')
	) {
		return  props.src.replace(/http/g, 'https')
	} else {
		return  props.src
	}
})
</script>
<style lang="scss" scoped>
:deep(.van-icon__image) {
  width: 60px;
  height: 60px;
}
</style>
