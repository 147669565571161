import { Lazyload } from 'vant'
import GlobalImage from '@/components/GlobalImage.vue'

const vantInit = (app) => {
  app.use(Lazyload, {
    lazyComponent: true,
    // loading: "",//加载时的图片
    // error: "",//错误时的图片
  })
  app.component('GlobalImage', GlobalImage)
}
export { vantInit }
