import { isNeedAppBridgeInit } from '@/hooks/useHdNeedBackPage';
import { showToast, showAlert, showLoading, hideLoading, showConfirm } from '../hooks/useVant'
import Axios from '../plugins/axios'
import router from '@/router'
import big from 'big.js'
import { useCountDown } from '@vant/use'
import { wyh5AppHost, appidCodeArr, hdh5AppHost } from '@/config'
import { store } from '../pinia/store'
import { sendDataToApp } from '../hooks/useBridge'
import { useDateFormat } from '@vueuse/core'
// import xss from 'xss'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { isJSON } from './encryptUtils'
import { importKeyFun, encryPublicKey } from './aesCbcUtil'
import { initAMap } from '@/hooks/useShareAMap'
import { validate, isEmpty } from 'class-validator'
import { currentDev } from '@/config/env'

/**
 * appidCode初始化 type=1,返回appidCode; type=2,返回appid
 */
const appidCodeInit = (appCode = 'WY', type = 1) => {
  if (appCode) {
    const urlAppId = appidCodeArr.filter((x) => x.appCode === appCode)[0].appid
    const appidCode = appidCodeArr.filter((x) => x.appCode === appCode)[0].code
    if (type === 1) {
      return appidCode
    } else {
      return urlAppId
    }
  } else {
    if (type === 1) {
      return 'WANG_YUE'
    } else {
      return 'wxd547642416441d89'
    }
  }
}
//获取对称密钥API
export const getAES = (isinit = false) => {
  return new Promise(async (resolve, reject) => {
    const storeData = store()
    const keyStr = storeData.keyInfo
    // const keyStr = storeData.keyStr
    if ((!storeData.keyInfo && !isinit) || isinit) {
      let url = `v5/secret/aesKey`
      Axios.get(url, {
        verNum: 1,
        os: storeData.appData.os || '104006',
        encryptPublicKey: await encryPublicKey(),
        // encryptPublicKey: $rsaEncrypt(),
        headers: {
          'c-id': storeData.headerInfo['c-id'],
          'c-meta': storeData.headerInfo['c-meta'],
        },
      }).then(async (res) => {
        let uncrypted = res.data.data
        // storeData.keyStr = uncrypted
        // resolve(uncrypted)
        const ikey = await importKeyFun(uncrypted)
        storeData.keyInfo = ikey
        resolve(ikey)
      })
    } else {
      resolve(keyStr)
    }
  })
}

const doAxios = (url, params = {}, type = 'post', isShowLoading = false) => {
  return new Promise(async (resolve, reject) => {
    if (isShowLoading) {
      showLoading()
    }
    // const key = AesDecrypt(keyString)
    const storeData = store()
    const key = storeData.keyInfo
    let headerObj = {
      headers: {
        'c-id': storeData.headerInfo['c-id'],
        'c-meta': storeData.headerInfo['c-meta'],
        // 'X-EFRESH-CLIENT': isWeiXin()
        //   ? 'wangyue_user_wxpublic'
        //   : 'wangyue_user_h5',
      },
    }
    if (!storeData.isShowNewSource) {
      const secret = storeData.appData.secret
      const session = storeData.appData.session
      headerObj.headers['X-EFRESH-SECRET'] = secret
      headerObj.headers['X-EFRESH-SESSION'] = session
    }
    let member = [url, params]
    let data = params
    if (type === 'get' || type === 'delete') {
      member = [
        url,
        {
          params: { ...data },
          ...headerObj,
        },
      ]
    } else if (type === 'post') {
      member = [url, data, headerObj]
    }
    Axios[type](...member)
      .then(async (res) => {
        if (res.data.code === 0) {
          storeData.isRefreshAppInputToken = false
          if (isShowLoading) {
            hideLoading()
          }
          resolve({ ...res.data })
          // console.group("接口数据调试信息1")
          // console.log("请求接口1", url);
          // console.log("请求数据1", params);
          // console.log("响应数据1", { ...res.data });
          // console.groupEnd()
        } else {
          hideLoading()
          if (res.data.code === 500 && url === 'v1/groupChat/info') {
            resolve({ ...res.data })
          }
          if (res.data.code === 500 && url === 'v6/order/prepare/BeforeCreateOrder' && isFromHd()) {
            resolve({ ...res.data })
          }
          if (res.data.code === 504 && url === 'v6/order/prepare/BeforeCreateOrder' && !isFromHd()) {
            resolve({ ...res.data })
          }
          if (
            res.data.code === 501 ||
            res.data.code === 505 ||
            res.data.code === 506 ||
            res.data.code === 507 ||
            res.data.code === 513 ||
            res.data.code === 518 ||
            res.data.code === 531 ||
            res.data.code === 1201 ||
            res.data.code === 707 ||
            res.data.code === 559
          ) {
            //501
            resolve({ ...res.data })
          }
          //登录失效
          if (res.data.code === 900 || res.data.code === 559 || res.data.code === 403) {
            storeData.isRefreshAppInputToken = false
            //此处调用全局登录退出方法，跳转原生登录页面
            if (!isApp() && !isHdApp()) {
              showToast(res.data.msg, 'fail')
            }
            //跳回登录
            //汗滴APP未登录时调用hdlogin桥
            if (isHdApp() && !storeData.appData.userId) {
              loginHd()
            } else {
              goLogin()                  
            }
            if (isHdApp()) {
              reject() 
            }
            return
          }
          if (res.data.code > 0 && res.data.code != 501 && url !== 'v1/groupChat/info') {
            //解密失败
            if (res.data.code === 523 || res.data.code === 560) {
              // storeData.keyStr = ''
              storeData.keyInfo = {}
              window.location.reload()
              return
            }
            if (res.data.code === 505 && res.data.msg === '当前技师服务时间已约满，请换个技师吧') {
              reject(res.data)
              return
            }
            showToast(res.data.msg, 'fail')
            reject(res.data)
          }
          //被禁用
          if (res.data.code === 531) {
            localStorage.setItem('stopinfo', JSON.stringify(res.data.stopinfo))
            //跳转禁用页面
            push('/myModule/login/stopLogin')
          }
          reject(res.data)
        }
      })
      .catch((err) => {
        hideLoading()
        if (!isApp()) {
          showToast('接口异常,请稍后重试', 'fail')
        }
        // 暂时对http状态码做特殊处理，后续废弃掉
        if (err.toString().includes('900')) {
          goLogin()
        }
        reject(err)
      })
  })
}

const doSendDataToApp = (url, params = {}, method = 'post', isShowLoading = false) => {
  console.log(url)
  console.log(params)
  console.log(method)
  return new Promise((resolve, reject) => {
    if (isShowLoading) {
      showLoading()
    }
    const storeData = store()
    const needEncode =
      storeData.isChooseAliPay &&
      [
        'pay/control/pay',
        'pay/control/addclock/pay',
        'pay/control/userRewardOrder/pay',
        'pay/control/userOpenVip/pay',
      ].includes(url)
    const param = {
      url: '/api/' + url,
      params: JSON.stringify(params),
      methodType: method == 'post' ? 1 : 0,
      needEncryption: 'NO',
      isNeedEncode: needEncode ? 'true' : 'false',
    }
    if (method === 'get') {
      param.url = jsonToUrl(param.url, params)
      param.params = ''
    }

    sendDataToApp('webviewRequestMethod', param, (response) => {
      console.log('原生端请求数据----开始')
      console.log('接口地址:', url)
      console.log('传参:', params)
      console.log('获取数据:', response)
      let res
      res = isJSON(response) ? JSON.parse(response) : response
      console.log('转格式后的数据返回', res)
      if (res && res.status) {
        if (res.code === 0) {
          hideLoading()
          res.data = isJSON(res.data) ? JSON.parse(res.data) : res.data
          resolve(res)
        } else {
          hideLoading()
          if (res.code === 500 && url === 'v1/groupChat/info') {
            resolve(res)
          }
          if (res.code === 500 && url === 'v6/order/prepare/BeforeCreateOrder' && isFromHd()) {
            resolve(res)
          }
          if (
            res.code === 501 ||
            res.code === 505 ||
            res.code === 506 ||
            res.code === 507 ||
            res.code === 513 ||
            res.code === 518 ||
            res.code === 531 ||
            res.code === 1201 ||
            res.code === 707 ||
            res.code === 559
          ) {
            //501
            resolve(res)
          }
          //登录失效
          if (res.code === 900 || res.code === 559 || res.code === 403) {
            storeData.isRefreshAppInputToken = false
            //此处调用全局登录退出方法，跳转原生登录页面
            if (!isApp() && !isHdApp()) {
              showToast(res.msg, 'fail')
            }
            //跳回登录
            goLogin()
          }
          if (res.code > 0 && res.code != 501 && url !== 'v1/groupChat/info') {
            showToast(res.msg, 'fail')
            reject(res)
          }
          //被禁用
          if (res.code === 531) {
            localStorage.setItem('stopinfo', JSON.stringify(res.stopinfo))
            //跳转禁用页面
            push('/myModule/login/stopLogin')
          }
          reject(res)
        }
        console.log('最终数据为：', res)
      } else {
        hideLoading()
        showToast(res.msg, 'fail')
        reject(res)
      }
      console.log('原生端请求数据----结束')
    })
  })
}

const addUrlParam = (url, name, value) => {
  let newUrl = url
  newUrl += newUrl.indexOf('?') == -1 ? '?' : '&'
  newUrl += encodeURIComponent(name) + '=' + encodeURIComponent(value + '')
  return newUrl
}

const jsonToUrl = (url, param) => {
  let result = url
  for (let key in param) {
    result = addUrlParam(result, key, param[key]) //传参
  }
  return result
}

// rpc校验
const doRpcCheck = (PostClass, param) => {
  console.log(PostClass)
  console.log(param)
  return new Promise(async (resolve) => {
    let post = new PostClass()
    for (let key in param) {
      post[key] = param[key]
    }
    console.log(post)
    let errNum = await validate(post).then((errors) => {
      console.log('validation failed. errors: ', errors)
      console.log(errors.length)
      return errors.length
    })
    console.log(errNum)
    resolve(errNum > 0)
  })
}

const isAndroid = () => {
  var u = navigator.userAgent
  var flag = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
  return flag && ((isFromApp() && !isWechat() && !isWeb()))
}

// 判断安卓浏览器
const isAndroidWeb = () => {
  return isAndroidSystem() && !isApp() && !isWeiXin()
}

/**
 * 判断是否android系统，和 ```isAndroid() ``` 函数有区别，
 * ```isAndroid()```函数还会判断是否来自App且不是微信，不是web浏览器
 * @returns boolean 是不是Android系统
 */
const isAndroidSystem = () => {
  var u = navigator.userAgent
  var flag = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
  return flag
}

const isIos = () => {
  var u = navigator.userAgent
  var flag = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  return flag && ((isFromApp() && !isWechat() && !isWeb()))
}

/**
 * 判断是否ios系统，和 ```isIos() ``` 函数有区别，
 * ```isIos()```函数还会判断是否来自App且不是微信，不是web浏览器
 * @returns boolean 是不是ios系统
 */
const isIOSSystem = () => {
  var u = navigator.userAgent
  var flag = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  return flag
}

const isApp = () => {
  return (isAndroid() || isIos()) && window.location.href.includes('isApp=1') && GLOBAL_FR !== 'hd'
}

const isHdApp = () => {
  return (isAndroid() || isIos()) && window.location.href.includes('isApp=1') && isFromHd()
}

const isHdFlutter = () => {
  return isHdApp() && localStorage.getItem('isFlutterApp')
}

const isFromApp = () => {
  //app环境判断
  const isFromApp = getUrlData('isApp') //微信公众号环境判断
  return isFromApp && isFromApp === '1'
}

const isWechat = () => {
  //公众号环境判断
  const isWechat = getUrlData('wechat') //微信公众号环境判断
  return isWechat && isWechat === '1'
}

const isWeb = () => {
  //web环境判断
  const isWeb = getUrlData('web')
  return isWeb && isWeb === '1'
}

const isWy = () => {
  //是否是内嵌往约环境，通过url参数来判断
  const isWy = getUrlData('isWy')
  return isWy && isWy === '1'
}

const isHd = () => {
  //是否是内嵌汗滴用户端app环境，通过url参数来判断
  return !window.location.href.includes('isApp=1') && isFromHd()
}

const isJwApp = () => {
  //是否是内嵌今往用户端app环境，通过url参数来判断
  return getUrlData('channelCode') === 'jw' || localStorage.getItem('isJwApp') === '1'
}

const isRiskyPlatform = () => {
  return isHuawei() || isViVo() || isOPPO()
}

const isHuawei = () => {
  return getUrlData('callback') === 'isHuawei'
}

const isOPPO = () => {
  return getUrlData('callback') === 'isOPPO'
}

const isViVo = () => {
  return getUrlData('callback') === 'isViVo'
}

// 校验原生端是否有某个方法
const checkAppHandle = async (appMethod) => {
  return new Promise((resolve) => {
    try {
      console.log('调用checkAppHandle桥方法')
      sendDataToApp('checkAppHandle', appMethod, (res) => {
        console.log('是否有方法', appMethod)
        console.log(res)
        console.log(res == 1)
        resolve(res == 1)
      })
    } catch (e) {
      resolve(false)
    }
  })
}

const apiInit = (urlObj) => {
  var _obj = {}
  for (let x in urlObj) {
    const _url = urlObj[x].url
    const _type = urlObj[x].type
    _obj[x] = async (params = {}, isShowLoading = false, type = _type) => {
      if (isApp()) {
        if (localStorage.getItem('isGoAppRequest') === '1') {
          return doSendDataToApp(_url, params, type, isShowLoading)
        } else {
          return doAxios(_url, params, type, isShowLoading)
        }
      } else {
        return doAxios(_url, params, type, isShowLoading)
      }
    }
  }
  return _obj
}

// 获取字典数据
const getDictList = (code) => {
  const storeData = store()
  const allDict = storeData.allDict
  const newArr = allDict.filter((item) => item.code == code)
  if (newArr.length > 0) {
    return newArr[0].infoList
  }
  return []
}

const httpFormat = (str) => {
  // http开头的图片链接转化为https开头
  if (
    str &&
    str.includes('http://oss') &&
    !str.includes('https') &&
    !str.includes('192.') &&
    !str.includes('127.') &&
    !str.includes('localhost')
  ) {
    return str.replace(/http/g, 'https')
  } else {
    return str
  }
}
//渠道可能的传参
const isPid = () => {
  //公众号环境判断
  const isPid = getUrlData('pid') //微信公众号环境判断
  return isPid
}
const isChannelCode = () => {
  //公众号环境判断
  const isChannelCode = getUrlData('channelCode') //微信公众号环境判断
  return isChannelCode
}
const isOutweb = () => {
  //判断是否是外部app内的webview打开
  return getUrlData('isOutweb') == 1
}

const push = (path = '/', query = {}) => {
  let queryClone = { ...query }
  const urlAppCode = getUrlData('appCode')
  const callback = getUrlData('callback')
  if (isPid()) {
    queryClone.pid = isPid()
  }
  if (isChannelCode()) {
    queryClone.channelCode = isChannelCode()
  }
  if (isApp()) {
    queryClone.isApp = 1
  }
  if (isHdApp()) {
    queryClone.isApp = 1
  }
  if (isWechat()) {
    queryClone.wechat = 1
  }
  if (isWeb()) {
    queryClone.web = 1
  }
  if (urlAppCode && urlAppCode != 'null') {
    queryClone.appCode = urlAppCode
  }
  if (callback && callback != 'null') {
    queryClone.callback = callback
  }
  if (isOutweb()) {
    queryClone.isOutweb = 1
  }
  router.push({
    path,
    query: queryClone,
  })
}

const replace = (path = '/', query = {}) => {
  let queryClone = { ...query }
  const urlAppCode = getUrlData('appCode')
  const callback = getUrlData('callback')
  if (isPid()) {
    queryClone.pid = isPid()
  }
  if (isChannelCode()) {
    queryClone.channelCode = isChannelCode()
  }
  if (isWechat()) {
    queryClone.wechat = 1
  }
  if (isWeb()) {
    queryClone.web = 1
  }
  if (isHdApp()) {
    queryClone.isApp = 1
  }
  if (urlAppCode && urlAppCode != 'null') {
    queryClone.appCode = urlAppCode
  }
  if (callback && callback != 'null') {
    queryClone.callback = callback
  }
  if (isOutweb()) {
    queryClone.isOutweb = 1
  }
  router.replace({
    path,
    query: queryClone,
  })
}

//判断是否是微信浏览器的函数
const isWeiXin = () => {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  return /MicroMessenger/i.test(window.navigator.userAgent) || isOutweb()
}

//判断是否是QQ浏览器的函数
function isQQ() {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  return /QQ/i.test(window.navigator.userAgent)
}

const back = () => {
  console.log('普通路由返回')
  console.log(router.options.history.state)

  const { current, back } = router.options.history.state
  if (isApp() || (isHdApp() && isNeedAppBridgeInit() && getUrlData('isFirstPage') && back !== null)) {
    // 汗滴app首页进入url会携带isFirstPage参数，根据此参数并结合路由判断是否需要调用原生端返回
    sendDataToApp('back', null, () => { })
    return
  }
  // 返回空页面兜底
  if (back === null) {
    if (isFromHd()) {
      // 首页二级页 -> 首页
      if (getUrlData('isFirstPage')) {
        backToHome()
        return
      }
      // 支付详情页 -> 订单页
      if (current.includes('/orderModule/myOrders/detail/index')) {
        if (isHdApp()) {
          sendDataToApp('changeTabAt', '2', () => { })
        } else {
          replace('/order')
        }
        return
      }
      // 会员卡中心 -> 我的页
      if (current.includes('/myModule/vipCard/index')) {
        if (isHdApp()) {
          sendDataToApp('changeTabAt', '3', () => { })
        } else {
          replace('/my')
        }
        return
      }
    } 
    if (isApp()) {
      backToHome()
    }
  }
  if (current && current.includes('/orderModule/payState/index') && isFromHd()) {
    if (isHdApp()) {
      sendDataToApp('changeTabAt', '2', () => { })
    } else {
      replace('/order')
    }
  } else {
    if (isHdApp()) {
      let path = back.indexOf('?') ? back.split('?')[0] : back
      if (['/service', '/order', '/my'].includes(path)) {
        replace(path, {
          ...getAllUrlParams(back)
        })
      } else {
        router.back()
      }
    } else {
      router.back()
    }
  }
}
//仅支持h5路由返回/特殊情况处理
const h5Back = () => {
  if (router.options.history.state.back === null) {
    backToHome()
  } else {
    router.back()
  }
}
//回到首页
const backToHome = (isSaveHistory = true) => {
  if (isApp() || isHdApp()) {
    sendDataToApp('backToHome', null, () => { })
  } else {
    console.log('跳转到首页')
    const storeData = store()
    if (isHd()) {
      storeData.tabbarIndex = 0
    }
    const goto = isSaveHistory ? locationPush : locationReplace
    if (getUrlData('channelCode')) {
      goto('/index', {
        channelCode: getUrlData('channelCode'),
      })
    } else if (isWeiXin() && storeData.urlChannelCode !== '') {
      goto('/index', {
        channelCode: storeData.urlChannelCode,
      })
    } else {
      goto('/index')
    }
  }
}

/**
 * @description: 返回方法，包含h5端和app端的返回
 * @param {*} isGoBackAppIndex:是否返回app的首页，默认false
 * @return {*}
 */
const utilGoBack = (isGoBackAppIndex = false) => {
  if (getUrlData('isFirstPage') === '1') {
    if (isHdApp() || isApp()) {
      if (isGoBackAppIndex) {
        sendDataToApp('back', 0)
        return
      }
      sendDataToApp('back')
    }
  } else {
    router.back()
  }
}

// 获取挡墙url所有参数
const getAllUrlParams = (urlPath) => {
  // 使用新窗口的location对象来获取当前页面的URL
  let url = urlPath || window.location.href
  // 创建一个URLSearchParams实例
  let params = new URLSearchParams(url.split('?')[1])
  let result = {}
  for (let pair of params.entries()) {
    if (result[pair[0]]) {
      result[pair[0]] = Array.isArray(result[pair[0]]) ? result[pair[0]] : [result[pair[0]]]
      result[pair[0]].push(pair[1])
    } else {
      result[pair[0]] = pair[1]
    }
  }
  return result
}

const h5RouterBack = () => {
  if (!router.options.history.state.back) return
  router.back()
}

const goLogin = async (query = {}) => {
  // 跳转到登录页(app端/公众号端兼容)
  const storeData = store()
  console.log('退出登录逻辑处理开始')
  console.log('能否刷新token的值', storeData.isRefreshAppInputToken)
  if (isApp() || isHdApp()) {
    // token失效，调原生端方法，跳转到原生端登录页面
    if (storeData.isShowNewSource) {
      console.log('新包~~~')
      if (!storeData.isRefreshAppInputToken) {
        console.log('可以调刷新~~~')
        sendDataToApp('goLogin', window.location.href, (res) => {
          storeData.isRefreshAppInputToken = true
          console.log('goLogin返回', res)
          if (res && (res === 1 || res === '1')) {
            // router.go(0)
            window.location.reload()
          } else {
            storeData.appData.userId = ''
            storeData.appData.basic = {}
            storeData.appData.growth = {}
            storeData.appData.stat = {}
            storeData.appData.info = {}
            storeData.appData.vip = {}
            sessionStorage.clear()
            // localStorage.clear()
          }
        })
      } else {
        storeData.appData.userId = ''
        storeData.appData.secret = ''
        storeData.appData.session = ''
        storeData.appData.userInfo = {}
        storeData.orderConfimAddress = {}
        sessionStorage.clear()
        // localStorage.clear()
        if (storeData.hasCookie > 0) {
          sendDataToApp('goLogin', window.location.href)
        }
      }
    } else {
      storeData.appData.userId = ''
      storeData.appData.secret = ''
      storeData.appData.session = ''
      storeData.appData.userInfo = {}
      storeData.orderConfimAddress = {}
      sessionStorage.clear()
      // localStorage.clear()
      if (storeData.hasCookie > 0) {
        sendDataToApp('goLogin', window.location.href)
      }
    }
  } else {
    console.log('跳转到登录页', query)
    storeData.appData.userId = ''
    storeData.appData.basic = {}
    storeData.appData.growth = {}
    storeData.appData.stat = {}
    storeData.appData.info = {}
    storeData.appData.vip = {}
    storeData.nowCurrentConversationID = ''
    sessionStorage.clear()
    // localStorage.clear()
    const allQuery = getAllUrlParams()
    console.log('当前路由path', router.currentRoute.value.path)
    console.log('当前路由参数', allQuery)
    storeData.sourcePage = { path: router.currentRoute.value.path, query: { ...allQuery } }
    if (isWeiXin()) {
      if (getUrlData('channelCode')) {
        getWxCode('/myModule/login/index?channelCode=' + getUrlData('channelCode'))
      } else {
        getWxCode('/myModule/login/index')
      }
    } else {
      replace('/myModule/login/index', { ...query })
    }
  }
}

//去投票
const goToVote = async () => {
  const storeData = store()
  if (storeData.appData?.userId) {
    push(isFromHd() ? '/hdPage/vote/index' : '/pages/vote/index')
  } else {
    if (await showConfirm(`登录后才能投票，先去登录吗？`, '温馨提示', '去登录')) {
      if (isFromHd()) {
        loginHd()
      } else {
        goLogin()

      }
    }
  }
}

//url参数转对象
const searchParamToObj = (url = location.href) => Object.fromEntries(new URL(url, import.meta.url).searchParams)

//folderName 文件夹
const getImageUrl = (folderName, iconName) => {
  return new URL(`../assets/imgs/${folderName}/${iconName}`, import.meta.url).href
}

const getHdImageUrl = (name) => {
  return new URL(`../assets/images/${name}`, import.meta.url).href
}

//folderName 文件夹
const getHooksFileUrl = (fileName) => {
  return new URL(`../hooks/${fileName}`, import.meta.url).href
}

const isSupportWebp = () => {
  let ua = navigator.userAgent
  let result
  if (isIos()) {
    let bv = ua.substring(ua.indexOf('iPhone OS ') + 10, ua.indexOf(' like'))
    result = (bv ? bv.split('_')[0] : 13) > 13
  } else if (isAndroid()) {
    let info = ua.split(';')[1]
    let bv = info.substring(info.indexOf(' Android ') + 9, info.length)
    result = (bv ? bv.split('.')[0] : 6) > 6
  }
  return result
}

//图片压缩后缀
const showImageUrl = (pic, cutType = '?x-oss-process=image/format,webp') => {
  if (
    !!pic &&
    pic.includes('http://oss') &&
    !pic.includes('https://thirdwx.qlogo.cn') &&
    !pic.includes('!watermark') &&
    !pic.includes('x-oss-process=video/snapshot') &&
    isSupportWebp()
  ) {
    return `${pic}${cutType}`
  } else {
    if (!!pic && pic.includes('http://img.')) {
      return pic.replace(/http/g, 'https')
    } else {
      return pic
    }
  }
}

const showVideoUrl = (relativePic) => {
  return `${relativePic}?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto`
}
// xss文本过滤
const xssFormat = (str = '', option = {}) => {
  return str
  // xss(str, {
  //   whiteList: {},
  //   ...option
  // })
}

/**
 * @description: xss对象过滤方法
 * @param {*} params:要过滤的对象
 * @param {*} strArr:需要过滤的对象中的key数组
 * @param {*} option:xss白名单
 * @return {*}
 */
const xssObjFormat = (params = {}, strArr = [], option) => {
  // strArr.forEach(item => {
  //   params[item] = xss(params[item], {
  //     whiteList: {},
  //     ...option
  //   })
  // })
}
// 压缩图片
const compressImage = (url) => {
  return url + '?x-oss-process=image/format,webp'
}

const mathPlus = (a = 0, b = 0, c = 0, d = 0, e = 0) => {
  //目前支持最多4个数字相加，应该够了~
  return new big(a).plus(b).plus(c).plus(d).plus(e) - 0
}
const mathMinus = (a = 0, b = 0, c = 0) => {
  return new big(a).minus(b).minus(c) - 0
}
const mathTimes = (a = 1, b = 1, c = 1) => {
  //目前支持最多三个数字相乘，应该够了~
  return new big(a).times(b).times(c) - 0
}
const mathDiv = (a = 1, b = 1) => {
  return new big(a).div(b) - 0
}

const resetObj = (newObj, reactiveObj, isClean = false) => {
  //reactive对象循环添加属性
  // isClean:是否清空旧对象
  if (isClean) {
    for (const key in newObj) {
      delete newObj[key]
    }
  }
  for (const key in reactiveObj) {
    newObj[key] = reactiveObj[key]
  }
}

const resetArr = (newArr, reactiveArr) => {
  //reactive数组循环添加属性
  newArr.length = 0
  reactiveArr.forEach((item) => {
    newArr.push(item)
  })
}

const formatNumShow = (num = 0, point = 2) => {
  //价格单位 页面显示初始化
  if (isNaN(num)) {
    return 0
  }
  return (num - 0).toFixed(point)
}
const formatNumSubmit = (num = 0) => {
  //价格单位 提交初始化
  return num - 0
}

const showSuccessAlert = async (text) => {
  // 单更新成功后，跳alert提示框，点击确定，固定时间后 回到上一页
  if (await showAlert(text)) {
    const countDown = useCountDown({
      time: 300,
      onFinish() {
        back()
      },
    })
    countDown.start()
  }
}

//时间戳转日期时间
const dateFormat = (stamp, type = 1, isArr = false) => {
  // 1:年月日 时分秒
  // 2:年月日 时分
  // 3:年月日
  if (!stamp) {
    return ''
  }
  let obj = {
    1: 'YYYY-MM-DD HH:mm:ss',
    2: 'YYYY-MM-DD HH:mm',
    3: 'YYYY-MM-DD',
    4: 'YYYY-MM-01',
    5: 'YYYY-MM-DD HH:mm',
    6: 'HH:mm',
    7: 'YYYY-MM',
    8: 'YYYY',
    9: 'YYYY.MM.DD',
  }
  const stampStr = stamp + ''
  let time = new Date(stamp).getTime()
  if (stampStr.indexOf('T') > -1) {
    const stampFilter = stamp.substring(0, stampStr.indexOf('.')).replace('T', ' ').replace(/-/g, '/')
    time = new Date(stampFilter).getTime() + 1000 * 60 * 60 * 8
  }
  if (type === 5) {
    let dayStr = useDateFormat(time, obj[type]).value
    let dateArr = dayStr.split(' ')
    let dayArr = dateArr[0].split('-')
    let timeArr = dateArr[1].split(':')
    if (isArr) return dayArr
    return dayArr[1] + '月' + dayArr[2] + '日' + timeArr[0] + '点'
  } else if (type === 7) {
    let dayStr = useDateFormat(time, obj[type]).value
    let dateArr = dayStr.split('-')
    if (isArr) return dateArr
  } else {
    return useDateFormat(time, obj[type]).value
  }
}

//返回当月天数
/**
 *
 * @param {String} str '2019-01-25'
 */
const daysInMonth = (str) => {
  const strArr = str.split('-')
  const days = new Date(strArr[0], strArr[1], 0).getDate()
  return days
}
//隐藏手机号码中间4位
const hideMobileNunmber = (v) => {
  if (/1[3-9]\d{9}/.test(v)) {
    return v.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
  }
  return v
}
//获取url参数
const getUrlData = (name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = (
    decodeURIComponent(window.location.search) ||
    decodeURIComponent(window.location.hash).substr(decodeURIComponent(window.location.hash).indexOf('?'))
  )
    .substr(1)
    .match(reg)
  if (r != null) return unescape(r[2])
  return null
}

/**
 * 把秒数转化为天、时、分、秒
 * 参数value是秒数
 */
const formatSeconds = (value, type = 1) => {
  var secondTime = Math.floor(value) // 秒
  var result = ''
  if (value < 60) {
    if (secondTime < 10) {
      secondTime = '0' + secondTime
    }
    result = `00'${secondTime}''`
  } else {
    if (secondTime >= 60) {
      let hours = Math.floor((secondTime % (24 * 3600 * 1000)) / (3600 * 1000))
      let minutes = Math.floor((secondTime % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = 0
      if (hours > 0) {
        hours = hours >= 10 ? hours : '0' + hours
      }
      if (minutes > 0) {
        minutes = minutes >= 10 ? minutes : '0' + minutes
      }
      if (seconds > 0) {
        seconds = seconds >= 10 ? seconds : '0' + seconds
      } else if (seconds === 0) {
        minutes = '01'
        seconds = '00'
      }
      if (type === 1) {
        result = `${minutes}'${seconds}''`
      } else {
        result = minutes * 60 * 1000
      }
    }
  }
  return result
}

/**
 * 把秒数转化为天、时、分、秒
 * 参数value是秒数
 */
const convertSeconds = (value) => {
  var secondTime = Math.floor(value) // 秒
  var result = ''
  if (value < 60) {
    if (secondTime < 10) {
      secondTime = '0' + secondTime
    }
    result = `00'${secondTime}''`
  } else {
    const hours = Math.floor(secondTime / 3600)
    secondTime %= 3600
    const minutes = Math.floor(secondTime / 60)
    secondTime %= 60
    console.log(hours, minutes, secondTime)
    let h = hours > 9 ? hours : '0' + hours
    let m = minutes > 9 ? minutes : '0' + minutes
    let s = secondTime > 9 ? secondTime : '0' + secondTime
    if (hours > 0) {
      result = `${h}'${m}'${s}`
    } else {
      result = `${m}'${s}`
    }
  }
  return result
}

//计算两个时间相差时长，返回分钟
const formatMinute = (date1, date2 = new Date().getTime()) => {
  if (date1) {
    const time1 = new Date(date1.replace(/-/g, '/')).getTime()
    const time2 = new Date(dateFormat(date2, 2).replace(/-/g, '/')).getTime()
    const subtime = time1 - time2 //计算时间差
    const days = Math.floor(subtime / (24 * 3600 * 1000))
    const hours = Math.floor((subtime % (24 * 3600 * 1000)) / (3600 * 1000))
    const minutes = Math.floor((subtime % (1000 * 60 * 60)) / (1000 * 60))
    const mins = days * 24 * 60 + hours * 60 + minutes
    return mins
  } else {
    return 1000
  }
}

// 根据某个字符串截取，获取前后字符串
const getStr = (string, str) => {
  var str_before = string.split(str)[0]
  var str_after = string.split(str)[1]
  var arr = [str_before, str_after]
  return arr
}

// 评论姓名过滤
const filterName = (str) => {
  if (str) {
    return str.substr(0, 1) + '***' + str.substr(-1, 1)
  }
  return ''
}

// 评论地址过滤
const filterAddress = (str) => {
  if (str) {
    return str.length > 5 ? `${str.substr(0, 5)}**` : `${str.substr(0, 3)}**`
  }
  return ''
}

const filterNumber = (value) => { }
//计算2个时间相差的天数，时间戳计算
const dateDifference = (sDate1, sDate2 = new Date().getTime()) => {
  //sDate1和sDate2是时间戳
  let dateSpan = sDate2 - sDate1
  dateSpan = Math.abs(dateSpan)
  let iDays = Math.floor(dateSpan / (24 * 3600 * 1000))
  return iDays
}

//maxUpload
const maxUpload = () => {
  const storeData = store()
  const allDict = storeData.allDict
  const filterList = allDict.filter((x) => x.code == 1031)[0].infoList
  const maxParams = filterList.filter((a) => a.value === '1032043')[0]
  return maxParams.label.split('_')
}
//获取浏览器唯一标识
const fpPromise = () => {
  return new Promise(async (resolve, reject) => {
    const fp = await FingerprintJS.load()
    // The FingerprintJS agent is ready.
    const result = await fp.get()
    const visitorId = result.visitorId
    resolve(visitorId)
  })
}
/**
 * 发送验证码倒计时
 * @param {*} params 定时器对象
 * @param {*} type type=1,短信验证码,type=2语音验证码
 */
const timeCount = (params = { isDisabled: false, time: 60, codeText: '获取验证码', timer: null }, type = 1) => {
  clearInterval(params.timer)
  params.isDisabled = true
  params.timer = setInterval(() => {
    params.time--
    params.codeText = type === 1 ? `(${params.time})重新获取` : `语音验证码已发送(${params.time}）`
    if (params.time < 1) {
      params.codeText = type === 1 ? '获取验证码' : '获取语音验证码'
      params.isDisabled = false
      clearInterval(params.timer)
    }
  }, 1000)
}
//复制文字
const toCopy = (str) => {
  let copyInput = document.createElement('input') //创建input元素
  document.body.appendChild(copyInput) //向页面底部追加输入框
  copyInput.readOnly = isIos()
  copyInput.setAttribute('value', str) //添加属性，将url赋值给input元素的value属性
  copyInput.select() //选择input元素
  document.execCommand('Copy') //执行复制命令
  showToast('复制成功') //弹出提示信息，不同组件可能存在写法不同
  //复制之后再删除元素，否则无法成功赋值
  copyInput.remove() //删除动态创建的节点
}
//动态加载js
const loadScript = (jsfile) => {
  if (!isInclude(jsfile)) {
    console.log('****************', jsfile)
    return new Promise((resolve, reject) => {
      var head = document.getElementsByTagName('head')[0]
      var script = document.createElement('script')
      script.type = 'text/javascript'
      console.log(script)
      console.log('head', head)
      script.src = jsfile
      head.appendChild(script)
      console.log('script', script.src)
      script.onload = script.onreadystatechange = function () {
        if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
          script.onload = script.onreadystatechange = null
          resolve()
        } else {
          reject()
        }
      }
    })
  }
}
const isInclude = (name) => {
  const js = /js$/i.test(name)
  const es = document.getElementsByTagName(js ? 'script' : 'link')
  for (var i = 0; i < es.length; i++) {
    if (es[i][js ? 'src' : 'href'].indexOf(name) != -1) {
      return true
    }
  }
  return false
}
// 判断是不是华为平台，用于安卓应用市场审核
// 1：华为， 2：应用宝， 3：小米， 4：oppo， 5：vivo  6：三星
const isHideShowComment = () => {
  const storeData = store()
  console.log('androidChannel', storeData.androidChannel)
  return (isAndroid() || jwAndroidApp()) && storeData.androidChannel === '1' //1华为平台不显示评价
}
//拆分数据,把对象拼接在url后面
const splitData = (data) => {
  let url = ''
  for (let k in data) {
    if (data.hasOwnProperty(k)) {
      let value = data[k] || ''
      url = url + '&' + k + '=' + encodeURIComponent(value)
    }
  }
  return url ? url.substring(1) : ''
}
// 拼接参数
const contractUrl = (url, data) => {
  return url + (url.indexOf('?') < 0 ? '?' : '&') + splitData(data)
}

//类似url?后面的str转为对象
const parseQueryString = (str, splitType = '&') => {
  const arr = str.split(splitType)
  let obj = {}
  for (let i = 0; i < arr.length; i++) {
    let arr2 = arr[i].split('=')
    // arr2的第一个成员做属性, 第二个成员做值
    obj[arr2[0]] = arr2[1]
  }
  return obj
}

/**
 * @description 自定义重定向浏览器跳转方法
 * @param {string} path 跳转路由，已自带/#，根据路由模式来
 * @param {object} query url带的参数转换
 *
 */
const locationPush = (path = '', query = {}) => {
  const urlAppCode = getUrlData('appCode') || getUrlData('state')
  try {
    let search = (isHd() ? hdh5AppHost : wyh5AppHost) + `${path}`
    if (Object.keys(query).length > 0) {
      search += '?'
      for (let key in query) {
        search += `${key}=${query[key]}&`
      }
      search = search.substring(0, search.length - 1)
      if (urlAppCode && urlAppCode != 'null' && !search.includes('appCode')) {
        search += `&appCode=${urlAppCode}`
      }
    } else {
      if (urlAppCode && urlAppCode != 'null') {
        search += `?appCode=${urlAppCode}`
      }
    }
    location.href = search
  } catch (error) {
    console.log('locationPush错误', error)
  }
}
/**
 * @description 自定义重定向浏览器跳转方法，不保留历史记录
 * @param {string} path 跳转路由，已自带/#，根据路由模式来
 * @param {object} query url带的参数转换
 *
 */
const locationReplace = (path = '', query = {}) => {
  const urlAppCode = getUrlData('appCode') || getUrlData('state')
  let search = (isHd() ? hdh5AppHost : wyh5AppHost) + `${path}`
  try {
    if (Object.keys(query).length > 0) {
      search += '?'
      for (let key in query) {
        search += `${key}=${query[key]}&`
      }
      search = search.substring(0, search.length - 1)
      if (urlAppCode && urlAppCode != 'null' && !search.includes('appCode')) {
        search += `&appCode=${urlAppCode}`
      }
    } else {
      if (urlAppCode && urlAppCode != 'null') {
        search += `?appCode=${urlAppCode}`
      }
    }
    history.replace(null, '', search)
  } catch (error) {
    location.replace(search)
    console.log('locationReplace错误', error)
  }
}

/**
 * 获取code
 * @param {string} url  重定向路由
 * @param {*} scope 授权方式 1.snsapi_userinfo 用户确认  2.snsapi_base 静默授权
 */
const getWxCode = (
  url = '/myModule/login/middle',
  scope = 'snsapi_userinfo',
  state = getUrlData('appCode') || 'WY',
) => {
  const appId = appidCodeInit(getUrlData('appCode'), 2)
  const local = (isHd() ? hdh5AppHost : wyh5AppHost) + url
  const oauthUrl = `
  https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
  window.location.replace(oauthUrl)
}

/**
 * 获取微信临时授权code，用于请求微信接口，该code每次获取后一旦使用即失效,需要重新获取
 * @param {*} url 重定向地址，微信code获取通过微信auth地址服务端重定向生成
 * @param {*} scope 微信auth服务必备参数，授权方式 1.snsapi_userinfo 用户确认  2.snsapi_base 静默授权
 * @param {*} state 其它参数
 */
const getWxCodeNoRefresh = async (url = '/myModule/login/middle', scope = 'snsapi_userinfo', state = getUrlData('appCode') || 'WY') => {
  const iframes = document.querySelectorAll('iframe')
  const wxCodeIframe = [...iframes].find(i => i.src.includes(url))
  let iframe = wxCodeIframe || document.createElement('iframe')
  const appId = appidCodeInit(getUrlData('appCode'), 2)
  const local = (isHd() ? hdh5AppHost : wyh5AppHost) + url
  const oauthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
  iframe.src = oauthUrl
  iframe.style.width = '1px'
  iframe.style.height = '1px'
  iframe.style.transform = 'translateX(-100vw)'
  document.body.append(iframe)
  return await getCodeParamFromUrl(iframe.contentWindow)
}

/**
 * 获取指定网站的url参数，如果未获取到则轮询，直到最大时间被消耗完饭回null
 * @param {*} origin 要获取url的主机
 * @param {*} frequency 每次获取后等待时间
 * @param {*} maxTime 任务最长时间
 * @returns 
 */
const getCodeParamFromUrl = function (origin, frequency = 100, maxTime = 1000 * 10) {
  return new Promise((resolve, reject) => {
    let startTime = Date.now();
    let intervalId = setInterval(() => {
      let url
      try {
        url = new URL(origin.location.origin + origin.location.hash.slice(1))
      } catch (err) {
        // 报错时在微信的auth还未完成重定向，因此此时没有code，避免报错构造1个空参数对象
        url = new URL('https://h5.wangyuedaojia.com')
      }
      let code = new URLSearchParams(url.search).get('code');
      if (code) {
        clearInterval(intervalId);
        resolve(code);
      }
      if (Date.now() - startTime >= maxTime) {
        clearInterval(intervalId);
        resolve(null);
      }
    }, frequency);
  });
}

/**
 * 获取路由path
 * @param {string} url  带域名的完整路径
 */
const getShortPath = (url) => {
  if (url.includes(wyh5AppHost)) {
    const _path = url.split('/#')[1]
    push(_path)
  } else {
    location.href = url
  }
}

/**
 * wechatLoginInit初始化
 */
const wechatLoginInit = () => {
  const urlAppCode = appidCodeInit(getUrlData('state')) || appidCodeInit(getUrlData('appCode'))
  console.log('urlAppCode', urlAppCode)
  return isWeiXin() && (urlAppCode === 'WANG_YUE' || urlAppCode === 'HAN_DI')
}

//时间格式转化-多久之前
const dateLongAgo = (stringTime) => {
  let str = stringTime
  //将字符串转换成时间格式
  let timePublish = new Date(str).getDate()
  let timeNow = new Date().getDate()
  console.log(timePublish)
  console.log(timeNow)
  let diffValue = timeNow - timePublish
  let diffDay = diffValue
  let diffYear = diffValue / 365
  let result = null
  if (diffYear > 1) {
    result = parseInt(diffYear) + '年前'
  } else if (diffDay >= 1) {
    result = parseInt(diffDay) + '天前'
  } else {
    result = '今天'
  }
  return result
}

// 获取定位
const getWebLocation = () => {
  const storeData = store()
  return new Promise(async (resolve) => {
    const res = await initAMap(2)
    console.log('有定位吗')
    console.log(res)
    let loc = {
      id: getUrlData('techId') || storeData.imShareLocationTechId,
      lng: res.lng ? res.lng : res[0],
      lat: res.lat ? res.lat : res[1],
    }
    resolve(loc)
  })
}

// 获取定位
const getAppLocation = () => {
  return new Promise((resolve) => {
    sendDataToApp('shareLocation', '', (loc) => {
      console.log('获取定位', loc)
      resolve(isAndroid() ? JSON.parse(loc) : loc)
    })
  })
}

// 获取顶部状态栏高度
const getIphoneStatusBarHeight = () => {
  return new Promise((resolve) => {
    sendDataToApp('iphoneStatusBarHeight', '', (res) => {
      console.log('状态栏高度', res)
      resolve(parseFloat(res.toString()))
    })
  })
}

// 校验是否过期
const checkIsExpire = () => { }

// 检查是否是新包
const checkIsNewPackage = async () => {
  return isApp() && (await checkAppHandle('judgeNewVersion'))
}

/**
 * 检查是否在白天时间范围内
 * @param {*} duration  07:00-19:00
 * @param {*} time 19:00
 */
const checkAtTimeDuration = (start, end, time) => {
  if (!start) return false
  if (!time) return false
  console.log(start, end, time)
  // const dura = duration.split('-')
  // let bookTime = new Date(time).getTime()
  // const startTime = `${dateFormat(new Date().getTime(), 3)} ${dura[0]}`
  // const endTime = `${dateFormat(new Date().getTime(), 3)} ${dura[1]}`
  // let start = new Date(startTime).getTime()
  // let end = new Date(endTime).getTime()
  // if (dura.length === 2 && ((bookTime > start) && (bookTime < end))) {
  //   return true
  // }
  var d = new Date(time)
  console.log(d > new Date(start) && d < new Date(end))
  return d > new Date(start) && d < new Date(end)
}

// 清图片缓存
const removeCache = (url) => {
  if (!url) return ''
  return url + '?v=' + new Date().getTime()
}

// 年龄格式化（北京/上海 年龄强制展示80后，85后，90后）
const ageFormatting = (item) => {
  console.log('传入年龄', item)
  const storeData = store()
  let cityCode = isApp() ? storeData.appData.cityCode : storeData.locationData.cityCode
  if (!item) return ''
  if (['110100', '310100'].includes(cityCode)) {
    return item.ageRange ? item.ageRange : ''
  } else {
    return item.age
  }
}

// 获取微信openid
const getOpenid = (
  url = '/myModule/login/resetOpenid',
  scope = 'snsapi_userinfo',
  state = getUrlData('appCode') || 'WY',
) => {
  const appId = appidCodeInit(getUrlData('appCode'), 2)
  const local = (isHd() ? hdh5AppHost : wyh5AppHost) + url
  localStorage.setItem('currentPath', JSON.stringify(encodeURIComponent(window.location.href)))
  const oauthUrl = `
  https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
  window.location.replace(oauthUrl)
}

//时间戳转日期时间
const dateFormatBySplit = (stamp, type = 1, isArr = false) => {
  // 1:年月日 时分秒
  // 2:年月日 时分
  // 3:年月日
  if (!stamp) {
    return ''
  }
  let obj = {
    1: 'YYYY/MM/DD HH:mm:ss',
    2: 'YYYY/MM/DD HH:mm',
    3: 'YYYY/MM/DD',
    4: 'YYYY/MM/01',
    5: 'YYYY/MM/DD HH:mm',
    6: 'HH:mm',
    7: 'YYYY/MM',
    8: 'YYYY',
  }
  const stampStr = stamp + ''
  let time = new Date(stamp).getTime()
  if (stampStr.indexOf('T') > -1) {
    const stampFilter = stamp.substring(0, stampStr.indexOf('.')).replace('T', ' ').replace(/-/g, '/')
    time = new Date(stampFilter).getTime() + 1000 * 60 * 60 * 8
  }
  if (type === 5) {
    let dayStr = useDateFormat(time, obj[type]).value
    let dateArr = dayStr.split(' ')
    let dayArr = dateArr[0].split('-')
    let timeArr = dateArr[1].split(':')
    if (isArr) return dayArr
    return dayArr[1] + '月' + dayArr[2] + '日' + timeArr[0] + '点'
  } else if (type === 7) {
    let dayStr = useDateFormat(time, obj[type]).value
    let dateArr = dayStr.split('-')
    if (isArr) return dateArr
  } else {
    return useDateFormat(time, obj[type]).value
  }
}

const checkIsNotNull = (val) => {
  console.log('传入', val)
  let newStr = ''
  if (!isEmpty(val)) {
    newStr = val.toString().replace(/\s/g, '')
  }
  return (
    !isEmpty(newStr) &&
    newStr !== 'null' &&
    newStr !== `'null'` &&
    newStr !== 'undefined' &&
    newStr !== `'undefined'` &&
    newStr.toString().length > 0
  )
}

const distanceJudge = (val) => {
  if (val < 1000) {
    return val + 'm'
  }
  if (val >= 1000) {
    return (val / 1000).toFixed(1) + 'km'
  }
}

const countSeconds = (params = { time: 60, timeText: 60, timer: null }) => {
  clearInterval(params.timer)
  if (params.time > 0) {
    params.timer = setInterval(() => {
      params.time--
      params.timeText = params.time
      if (params.time < 1) {
        clearInterval(params.timer)
      }
    }, 1000)
  }
}

const isFixedHeader = () => {
  //是否做h5顶部兼容
  return isHdApp()
}

// 判断当前h5环境是否登录
const checkHdLogin = () => {
  // 传入isShouldToLogin为true的值表示检测到未登录会让他跳转到登录页面，默认为false
  const storeData = store()
  const isLoginSuccess = !!storeData.appData.userId
  if (!isLoginSuccess) {
    goLogin()
  }
  return isLoginSuccess
}

// 数字转换
const numConversion = (val) => {
  let total = val != '' ? val : 0
  total = total.toString().indexOf('.') != -1 ? parseFloat(total).toFixed(2) : total
  return total
}

const formatDistance = (val) => {
  if (val == undefined) return
  if (val == 0) {
    return '0m'
  }
  return val > 1 ? `${val}km` : `${val * 1000}m`
}

// 技师接单量数字转换
const acceptOrderNumRounding = (val) => {
  let total = val != '' ? val : 0
  let result = ''
  if (total > 10) {
    result = total + '+'
  } else {
    result = total
  }
  return result
}

// 销量数字转换
const salesNumRounding = (val) => {
  let total = val != '' ? val : 0
  let result = ''
  if (total > 10) {
    result = total + '+'
  } else {
    result = '10+'
  }
  return result
}

const playAudio = (audioStr = 'zan.mp3') => {
  // 播放音频方法
  const collectAudioEl = document.getElementById('collectAudioEl')
  if (!collectAudioEl) {
    var body = document.getElementsByTagName('body')[0]
    var audioEl = document.createElement('audio')
    audioEl.src = getAudio(audioStr)
    audioEl.id = 'collectAudioEl'
    body.appendChild(audioEl)
    document.getElementById('collectAudioEl').play()
  } else {
    document.getElementById('collectAudioEl').play()
  }
}

// 费用价格明细
const feeList = (orderInfo) => {
  if (orderInfo && orderInfo.length > 0) {
    orderInfo.forEach((item) => {
      if (item.type == 'productFee') {
        item.name = '订单总价'
      }
    })
    orderInfo = orderInfo.filter((item) => item.money != 0)
    return orderInfo
  } else {
    return []
  }
}

const getNowVersionCodeForH5 = () => {
  // 获取当前h5对应的版本号
  return ''
}

// 显示汗滴内容
const isFromHd = () => {
  return GLOBAL_FR === 'hd' || window.location.href.includes('handi.html')
}

// link跳转
const domainPush = (url = '', linkQuery = {}) => {
  if (isFromHd() && url && url.includes('?techType=2')) {
    //?techType表示下单的渠道，2表示在往约公众号下的汗滴技师单，8是汗滴公众号下的单，往约跟汗滴公用同一个im，在汗滴公共号中im消息涉及techType为2的，均弹出toast提示让其前往往约app或者往约公众号查看
    showToast('请前往往约公众号或往约app进行查询和使用')
    return
  }
  if (url.includes('http')) {
    // http或者https外部链接直接跳转
    if (['https://wyh5.wangyuedaojia.com/rule/orderAgainstGuide.html'].includes(url)) {
      push('/pages/staticPage/index', { pageId: 'AGAINSTGUIDE' })
    } else {
      window.location.href = url
    }
  } else {
    let query = { ...linkQuery }
    let link = ''
    let item = url

    if ((url.indexOf('app:order') > -1 || url.indexOf('app:productCode') > -1 || url.indexOf('app:techDetail') > -1) && url.indexOf('?') > -1) {
      // 新版本消息有自定义参数，目前针对app:order，app:productCode，app:techDetail做特殊处理，走新逻辑
      let param = getAllUrlParams(url.replaceAll(':', ''))
      if (url.includes('app:order')) {
        query['orderId'] = param['id']
        link = '/orderModule/myOrders/detail/index'
      } else if (url.includes('app:productCode')) {
        if (isFromHd()) {
          query['productCode'] = param['code']
          link = '/projectModule/productDetail/index'
        } else {
          query['productCode'] = param['code']
          link = '/productModule/productDetail/index'
        }
      } else if (url.includes('app:techDetail')) {
        if (isFromHd()) {
          query['tecId'] = param['id']
          link = '/projectModule/technicianDetail/index'
        } else {
          query['techId'] = param['id']
          link = '/technicianModule/technicianDetail/index'
        }
      }
    } else {
      if (item.includes('app:tech')) {
        if (item.includes('app:techDetail')) {
          if (!item.split(':')[2]) {
            showToast('缺少技师Id')
            return false
          }
          if (isFromHd()) {
            query['tecId'] = item.split(':')[2]
            link = '/projectModule/technicianDetail/index'
          } else {
            query['techId'] = item.split(':')[2]
            link = '/technicianModule/technicianDetail/index'
          }
        } else {
          if (item.indexOf('defaultQuery=') > -1) {
            let param = item.substring(item.indexOf('defaultQuery=') + 13, item.length)
            if (param !== '') {
              query['defaultQuery'] = param
            }
          }
          if (item.includes('app:techRecommend')) {
            if (item.includes('app:techRecommend:')) {
              query['sort'] = item.split(':')[2]
            }
          }
          if (isFromHd()) {
            link = '/confirmOrderModule/chooseTechnician/index'
          } else {
            link = '/technicianModule/technicianList/index'
          }
        }
      } else if (item.includes('app:productCode')) {
        if (!item.split(':')[2]) {
          showToast('缺少套餐code')
          return false
        }
        if (isFromHd()) {
          query['productCode'] = item.split(':')[2]
          link = '/projectModule/productDetail/index'
        } else {
          query['productCode'] = item.split(':')[2]
          link = '/productModule/productDetail/index'
        }
      } else if (item.includes('app:product')) {
        link = '/productModule/productList/index'
      } else if (item.includes('app:discounts')) {
        link = '/myModule/myCoupon/dailySpecial'
      } else if (item.includes('app:vip')) {
        // im消息中使用的是app:vipCard，金刚区跟banner位置使用的是app:vip
        if (item.includes('app:vipCard:') || item.includes('app:vip:')) {
          query['vipCardId'] = item.split(':')[2]
        }
        link = '/myModule/vipCard/index'
      } else if (item.includes('h5:')) {
        link = item.replace('h5:', '')
      } else if (item.includes('app:wallet')) {
        link = '/myModule/myWallet/index'
      } else if (item.includes('app:growthCenter')) {
        if (item.includes('app:growthCenter:')) {
          query['levelCode'] = item.split(':')[2]
        }
        link = '/myModule/growthCenter/index'
      } else if (item.includes('app:order')) {
        if (!item.split(':')[2]) {
          showToast('缺少订单Id')
          return false
        }
        query['orderId'] = item.split(':')[2]
        link = '/orderModule/myOrders/detail/index'
      } else if (item.includes('app:userReward')) {
        link = '/myModule/myRewardRecord/index'
      } else if (item.includes('app:coupon')) {
        link = '/myModule/myCoupon/index'
      } else if (item.includes('app:informAgainst')) {
        link = '/myModule/report/record/index'
      }
    }
    push(link, { ...query })
  }
}

// 检查数组是否为空
const checkArray = (arr) => {
  if (!arr) return false
  return Array.isArray(arr) && arr.length > 0
}

// app端拨打电话
const awakeAppPhoneCall = (phone) => {
  // 线上往约老包awakeAppCallPhone桥方法之前未使用过，ios原生端对该方法做了改动，此处需要对其做下兼容处理。往约安卓端，以及汗滴ios跟安卓不受影响
  sendDataToApp('awakeAppCallPhone', { toPhoneNumber: (isApp() && isIos()) ? ('telprompt://' + phone.replaceAll('-', '')) : phone })
}

// 检查对象是否为空
const checkObject = (obj) => {
  if (!obj) return false
  return Object.keys(obj).length > 0
}

// 登录汗滴app
const loginHd = (str = '') => {
  // str用于汗滴首页跳转到需要登录的h5页面，由于路由拦截导致用户在登录页不登录的情况下直接返回，导致页面白屏问题
  if (isHdApp()) {
    sendDataToApp('hdLogin', str, () => { })
  } else {
    replace('/myModule/login/index')
  }
}

// 汗滴退出登录
const logoutHd = () => {
  const storeData = store()
  storeData.appData.userId = ''
  storeData.appData.basic = {}
  storeData.appData.growth = {}
  storeData.appData.stat = {}
  storeData.appData.info = {}
  storeData.appData.vip = {}
  storeData.hasCookie = 0
}

// 获取app状态栏高度
const getAppStatusBarHeight = () => {
  const storeData = store()
  const topAreaHeight = 40 // 安卓默认设置高度为40，苹果通过桥接方法获取状态栏高度存入缓存
  const phoneStatusBarHeight = isHdApp() ? ((storeData.phoneStatusBarHeight || topAreaHeight) + 'px') : '0px'
  return phoneStatusBarHeight
}

// 获取支付方式type转文案
const paymentText = (type) => {
  // 支付宝0,微信1,微信公众号2,微信小程序3,用户/技师余额99 微信组合100 支付宝组合101 小程序组合102 银联组合103 (组合支付时，系统自动扣除用户所有余额)
  if (!type && type !== 0) return ''
  const obj = {
    '0': '支付宝',
    '1': '微信',
    '2': '微信公众号',
    '3': '微信小程序',
    '99': '余额',
    '100': '微信组合',
    '101': '支付宝组合',
    '102': '小程序组合',
    '103': '银联组合'
  }
  return obj[type.toString()]
}

// 获取channelCode
const getUrlChannelCode = () => {
  let channelCode = getUrlData('channelCode') || ''
  console.log('---------')
  console.log(router.currentRoute.value.name)
  if (location.href.includes('/myModule/login/index?channelCode=')) {
    channelCode = location.href.substring(location.href.indexOf('channelCode=') + 12, location.href.length)
  }
  return channelCode
}

// 汗滴app获取位置信息
const getHdAppLocation = () => {
  return new Promise(async (resolve) => {
    let nowLocation = {}
    nowLocation = await getAppLocation()
    if (!nowLocation || !nowLocation.adcode) {
      if (currentDev === 2) {
        showToast('获取app位置失败')
      }
      // 获取原生端定位信息失败取缓存数据
      const storeData = store()
      nowLocation = {
        adcode: storeData.locationData.adcode,
        cityCode: storeData.locationData.cityCode,
        cityName: storeData.locationData.cityName,
        lng: storeData.locationData.longitude,
        lat: storeData.locationData.latitude,
        address: storeData.locationData.locationAddress
      }
      console.log('使用已缓存的定位数据', nowLocation)
    }
    resolve(nowLocation)
  })
}
//获取ios底部安全区域
const getAPPSafeAreaHeight = () => {
  const storeData = store()
  return isHdApp() ? storeData.safeAreaBottomHeight || 0 : 0
}
// 金额转换
const moneyTranslate = (num = 0, point = 2) => {
  if (!num) return 0
  if (Number.isInteger(num)) {
    return num
  } else {
    return num.toFixed(point)
  }
}

// 键盘弹出，页面重绘，将获得焦点的元素滚动至可视区域内
const scrollIntoView = () => {
  if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
    setTimeout(() => {
      document.activeElement.scrollIntoView(true)
    }, 300)
  }
}

const judgeIsBigCity = () => {
  const storeData = store()
  const bigCityCodes = ['110100', '310100', '440300']
  storeData.isHdBigCity = bigCityCodes.includes(storeData.appData.cityCode)
  console.log(storeData.isHdBigCity, 'isHdBigCity*****')
}

// 汗滴app分享url地址兼容
const hdAppShareData = (obj = {}) => {
  let shareObj = obj
  let url = shareObj.shareUrl
  let icon = shareObj.shareIconUrl
  let title = shareObj.title
  let subTitle = shareObj.subTitle
  if (isHdApp()) {
    if (url.includes('?isApp=1&')) {
      shareObj.shareUrl = url.replace('?isApp=1&', '?')
    } else if (url.includes('&isApp=1')) {
      shareObj.shareUrl = url.replace('&isApp=1', '')
    } else if (url.includes('?isApp=1')) {
      shareObj.shareUrl = url.replace('?isApp=1', '')
    }
    if (title === '往约一点、轻松到家！') {
      shareObj.title = '上门理疗·就选汗滴'
    }
    if (subTitle === '往约-您的私人健康师！') {
      shareObj.subTitle = '您身边的理疗专家'
    }
    if (['http://oss.wangyuedaojia.com/upload/20221107/c71e83a18d6c4426a45a0807df917db9.png'].includes(icon)) {
      shareObj.shareIconUrl = 'https://oss.wangyuedaojia.com/upload/20240718/47430de8597045009715b3ba2c7477ec.png'
    }
    console.log('最新数据', shareObj)
    return shareObj
  } else {
    console.log('最新数据', shareObj)
    return shareObj
  }
}

// 获取汗滴足迹数据
const getFootPrintData = () => {
  return new Promise((resolve) => {
    sendDataToApp('getFootprint', null, (res) => {
      console.log('桥返回', res)
      resolve(res)
    })
  })
}

// 取10的倍数
const numToTen = (num) => {
  if (num > 9) {
    return Math.floor(num / 10) * 10
  } else {
    return num
  }
}

// 模拟打电话
const doCallAction = (phoneNum) => {
  if (!phoneNum) {
    return false
  }
  let callLink = 'tel:' + phoneNum
  let link = document.createElement('a')
  link.setAttribute('href', callLink)
  link.setAttribute('style', 'visibility:hidden')
  document.body.appendChild(link)
  link.click()
}

// 获取退款时间
const getRefundTime = () => {
  let list = getDictList(1031)
  let result = 0
  list.forEach((a) => {
    if (a.value === '1032046') {
      result = a.label
    }
  })
  let hour = (parseInt(result) / 60) + ''
  return parseInt(result) % 60 > 0 ? parseFloat(hour).toFixed(1) : parseInt(hour)
}

// 今往内嵌，判断是否是iOS
const jwIOSApp = () => {
  var u = navigator.userAgent
  var flag = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  return flag && isJwApp()
}

// 今往内嵌，判断是否是Android
const jwAndroidApp = () => {
  var u = navigator.userAgent
  var flag = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
  return flag && isJwApp()
}

// 判断是否有空值
const hasEmptyObj = (json, key) => {
  let result = false
  for (const jsonKey in json) {
    if (jsonKey !== key) {
      result = (json[jsonKey] === null || json[jsonKey] === undefined || json[jsonKey] === 'null' || json[jsonKey] === 'undefined' || json[jsonKey] === '')
    }
  }
  return result
}

export {
  isWechat,
  isWeb,
  isFromApp,
  getUrlData,
  apiInit,
  doAxios,
  doRpcCheck,
  isAndroid,
  isAndroidSystem,
  isIos,
  isIOSSystem,
  isApp,
  isHdApp,
  isHdFlutter,
  isJwApp,
  push,
  replace,
  getImageUrl,
  getHdImageUrl,
  getHooksFileUrl,
  mathPlus,
  mathMinus,
  mathTimes,
  mathDiv,
  resetObj,
  resetArr,
  formatNumShow,
  formatNumSubmit,
  showImageUrl,
  back,
  h5RouterBack,
  showSuccessAlert,
  dateFormat,
  hideMobileNunmber,
  formatSeconds,
  convertSeconds,
  getStr,
  compressImage,
  filterName,
  filterAddress,
  isWeiXin,
  isQQ,
  isHuawei,
  isViVo,
  isOPPO,
  dateDifference,
  daysInMonth,
  showVideoUrl,
  maxUpload,
  fpPromise,
  toCopy,
  getDictList,
  httpFormat,
  formatMinute,
  goLogin,
  backToHome,
  xssFormat,
  loadScript,
  isHideShowComment,
  timeCount,
  contractUrl,
  searchParamToObj,
  splitData,
  parseQueryString,
  xssObjFormat,
  h5Back,
  locationPush,
  locationReplace,
  getWxCode,
  getWxCodeNoRefresh,
  getShortPath,
  goToVote,
  appidCodeInit,
  wechatLoginInit,
  checkAppHandle,
  dateLongAgo,
  getWebLocation,
  getAppLocation,
  getIphoneStatusBarHeight,
  checkIsExpire,
  checkIsNewPackage,
  checkAtTimeDuration,
  removeCache,
  ageFormatting,
  getOpenid,
  dateFormatBySplit,
  checkIsNotNull,
  distanceJudge,
  countSeconds,
  isWy,
  isHd,
  isFixedHeader,
  checkHdLogin,
  numConversion,
  formatDistance,
  acceptOrderNumRounding,
  salesNumRounding,
  playAudio,
  feeList,
  getNowVersionCodeForH5,
  isFromHd,
  utilGoBack,
  domainPush,
  isAndroidWeb,
  awakeAppPhoneCall,
  checkArray,
  checkObject,
  loginHd,
  logoutHd,
  getAppStatusBarHeight,
  paymentText,
  getAllUrlParams,
  getUrlChannelCode,
  getHdAppLocation,
  getAPPSafeAreaHeight,
  moneyTranslate,
  scrollIntoView,
  judgeIsBigCity,
  hdAppShareData,
  getFootPrintData,
  isRiskyPlatform,
  numToTen,
  doCallAction,
  getRefundTime,
  jwIOSApp,
  jwAndroidApp,
  hasEmptyObj
}
