/**
 * AES+RSA加解密工具类
 */
import utf8 from 'crypto-js/enc-utf8'
import CryptoJSCore from 'crypto-js/core'
import AES from 'crypto-js/aes'
// import JSEncrypt from 'encryptlong'

/** 随机生成固定位数或者一定范围内的字符串数字组合
 * @param {Number} min 范围最小值
 * @param {Number} max 范围最大值，当不传递时表示生成指定位数的组合
 * @param {String} charStr指定的字符串中生成组合
 * @returns {String} 返回字符串结果
 * */
const randomRange = (min, max, charStr) => {
  var returnStr = "",
    range;
  if (typeof min == 'undefined') {
    min = 16;
  }
  if (typeof max == 'string') {
    charStr = max;
  }
  range = ((max && typeof max == 'number') ? Math.round(Math.random() * (max - min)) + min : min);
  charStr = charStr || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < range; i++) {
    var index = Math.round(Math.random() * (charStr.length - 1));
    returnStr += charStr.substring(index, index + 1);
  }
  return returnStr;
}
const ReqIv = randomRange()
// const ReqIv = 'DYgjCEIMVrj2W9xN'
const iv = utf8.parse(ReqIv) //十六位十六进制数作为密钥偏移量
/**
 * AES加密
 *
 * @param word 加密内容
 * @param keyStr 十六位的十六进制数作为 加解密的密钥，一般是动态生成//abcdefgabcdefg12
 */
const AesEncrypt = (word, keyStr) => {
  keyStr = keyStr ? keyStr : 'HdBbAxyfM6vuU5wq'
  var key = utf8.parse(keyStr)
  var srcs = utf8.parse(word)
  var encrypted = AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJSCore.mode.CBC,
    padding: CryptoJSCore.pad.Pkcs7,
  })
  return encrypted.toString()
}

//AES解密
const AesDecrypt = (word, keyStr) => {
  keyStr = keyStr ? keyStr : 'HdBbAxyfM6vuU5wq'
  var key = utf8.parse(keyStr)
  var decrypt = AES.decrypt(word ? word : '', key, {
    iv: iv,
    mode: CryptoJSCore.mode.CBC,
    padding: CryptoJSCore.pad.Pkcs7,
  })
  return utf8.stringify(decrypt).toString()
}

/**
 * AES加密
 * 用生成的密钥加密  得到密文
 *
 * @param content 原文
 * @param keyStr 16位密钥
 */

const getAESEncryption = (content, keyStr) => {
  var encryption = AesEncrypt(content, keyStr)
  // debugger;
  return encryption
}

/**
 * AES解密
 * 根据返回的aeskey 解密  得到原文
 *
 * @param content 密文
 * @param aeskey  请求接口后返回的密钥
 */
const getAESDecryption = (content, aeskey) => {
  var decryption = AesDecrypt(content, aeskey)
  return decryption
}

//keys
//  RSA服务端公钥
const serverPubkey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQClC2MafxQ8Gn1qbqYuCttUoSUMtcykcYi3IZYYDvK4MY7MtHtXzrvEcJDeXW9aSQtvoVEtLB1fQBj41ScHNF5oWwNy9anms86xx8ry1eP5uHAT+MrW9mVrLkftB9EktHblQwUgTs5dRhTXAF3v12SkmOV1UBr4AfFYb9lpsximbQIDAQAB`
//  RSA本地公钥
const localPubkey =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDBkVgHK9OtoNv5bo9B8HL8MkmqFiHSpLdjlemYZ2EWq7baj09k6UvwF+B74q8Z+07Us92I2/N9kDtl5rA/Ent7NTGeO6yYo29H9D0O3yxPdIAJ/SU1gvFomebXBgUKF9Nd+zw4td4aWBBiQ6lv+jKAbLlQ6Y5jQfXBZ1ENVyhyQwIDAQAB'
//   RSA本地私钥
const localPrivatekey =
  'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAMGRWAcr062g2/luj0HwcvwySaoWIdKkt2OV6ZhnYRarttqPT2TpS/AX4Hvirxn7TtSz3Yjb832QO2XmsD8Se3s1MZ47rJijb0f0PQ7fLE90gAn9JTWC8WiZ5tcGBQoX0137PDi13hpYEGJDqW/6MoBsuVDpjmNB9cFnUQ1XKHJDAgMBAAECgYAJnGPYh06NUm1ZpympxnyPFRgfP9YSFWedpDXjAU8Kxx/fHjp88jB5DVZyEEFNcvLpEL0xLGqck2q/loKtvwcucsc60ulBgO7vn99pipUTCUk7VBGcPPu7KlxbfcGTH8/yzzQWEbOIqkEJKR24DHGX4Kp/97Rdn+BsUWyMfoJbsQJBAOiNkxAbqpbj/whUGPsfagm1QVMzhP8iBappltz0uQphghpqC1wvW1jodh/sxJ6etRKqNmWMMnrn6Yqf9mlwcv0CQQDVFYVCN67IEIMTUm15KjE6n59+mbWpGm2gsnHfV66QRXBSMjb3DwMkyO6lKZ4gwi+PXoNKjS3E2PXFBZcU1J4/AkEAry+Hq8jLt6PFyaZgszy1Ct8qeN0EwZYFopysc4M/qhxOgEuGh0l9jPqBkgcxDzULvyXOmK0GkiQGgNGENWXqmQJAN9u4Xy9xpgiOE9ab1BN3bELD8NAiiv3ely2wJDg8Dp3lX+bB+en5jP8PcESEq1m8Vf0UtcpkoSGu68MXY+KIyQJAMi9L7QpUIQTlZ251OQydoB6RD4qGKaSDnQulfLtem1LC4cXZKlyAfEpdlvvDTw1a8oE469ohD7J2KsNWVqXv9A=='

/**
 * RSA加密
 *
 * @param data 加密内容
 */

// const $rsaEncrypt = () => {
//   let jse = new JSEncrypt()
//   // data = localPubkey
//   // 加密用的是服务端公钥serverPubkey
//   jse.setPublicKey(serverPubkey)
//   return jse.encryptLong(localPubkey)
// }

/**
 * RSA解密
 *
 * @param data 解密内容
 */

// const $rsaDecrypt = (data) => {
//   let jse = new JSEncrypt()
//   jse.setPrivateKey(localPrivatekey)
//   return jse.decryptLong(data)
// }

/**
 * KEY字符串再次加密
 */
const compileStr = (code) => {
  var c = String.fromCharCode(code.charCodeAt(0) + code.length)
  for (var i = 1; i < code.length; i++) {
    c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1))
  }
  return escape(c)
}
/**
 * KEY字符串进行解密
 * @param {Object} code
 */
const uncompileStr = (code) => {
  code = unescape(code)
  var c = String.fromCharCode(code.charCodeAt(0) - code.length)
  for (var i = 1; i < code.length; i++) {
    c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1))
  }
  return c
}
/**
 * 判断是不是json字符串
 * @param {String} str
 */
const isJSON = (str) => {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str)
      if (typeof obj === 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log('error：' + str + '!!!' + e)
      return false
    }
  }
}



export {
  AesEncrypt,
  AesDecrypt,
  getAESEncryption,
  getAESDecryption,
  // $rsaEncrypt,
  // $rsaDecrypt,
  isJSON,
  ReqIv
}
