// 汗滴需要使用原生端桥接back方法的页面
const isNeedAppBridgeInit = () => {
  const routeNameList = [
    '/myModule/locateAddress/index',
    '/pages/point/signIn',
    '/myModule/vipCard/index',
    '/pages/recruit/index',
    '/pages/invite/friend',
    '/projectModule/technicianDetail/index',
    '/projectModule/productDetail/index',
    '/pages/recruit/masseur',
    '/pages/recruit/partner'
  ]
  if (routeNameList.some((x) => window.location.href.includes(x))) {
    return true
  }
}

export { isNeedAppBridgeInit }