// h5与app端交互hooks
import { isAndroid, isHdApp, isIos, jwAndroidApp, jwIOSApp } from '@/utils'
/* global WebViewJavascriptBridge */

const setupWebViewJavascriptBridge = (callback) => {
  console.log('locationlocationlocationlocation', location)
  
  if (isHdApp()) {
    //Flutter使用
    if (window.flutter_inappwebview) {
      callback(window.flutter_inappwebview)
    } else {
      document.addEventListener(
        'flutterInAppWebViewPlatformReady',
        () => {
          callback(window.flutter_inappwebview)
        },
        false,
      )
    }
  } else if (isAndroid() || jwAndroidApp()) {
    //Android使用
    if (window.WebViewJavascriptBridge) {
      callback(WebViewJavascriptBridge)
    } else {
      document.addEventListener(
        'WebViewJavascriptBridgeReady',
        () => {
          callback(WebViewJavascriptBridge)
        },
        false,
      )
    }
  } else if (isIos() || jwIOSApp()) {
    //iOS使用
    if (window.WebViewJavascriptBridge) {
      return callback(WebViewJavascriptBridge)
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback)
    }
    window.WVJBCallbacks = [callback]
    var WVJBIframe = document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    WVJBIframe.src = 'https://__bridge_loaded__'
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(() => {
      document.documentElement.removeChild(WVJBIframe)
    }, 0)
  }
}

// js调APP方法 （参数分别为:app提供的方法名  传给app的数据  回调）
const sendDataToApp = (name, data, callback = () => {}) => {
  console.log('桥方法名', name)
  if (isHdApp() && ['webviewNotDestroy'].includes(name)) return false
  setupWebViewJavascriptBridge((bridge) => {
    let filterData
    if (typeof data === 'string') {
      filterData = data
    } else if (typeof data === 'object') {
      filterData = JSON.stringify(data)
    }
    if (isHdApp()) {
      bridge.callHandler(name, filterData, callback).then((res) => callback(res))
    } else {
      bridge.callHandler(name, filterData, callback)
    }
  })
}

// APP调js方法 （参数分别为:js提供的方法名  回调）
const registerHandler = (name, callback) => {
  setupWebViewJavascriptBridge((bridge) => {
    bridge.registerHandler(name, (data, responseCallback) => {
      callback(data, responseCallback)
    })
  })
}
export { sendDataToApp, registerHandler }

// //h5传递数据给app端方法
// const sendDataToApp = (funName, data = '') => {
//   if (isIos()) {
//     //sendData需要提前和ios端定义好
//     window.webkit.messageHandlers[funName].postMessage(data)
//     return
//   }
//   if (isAndroid()) {
//     // APP这个对象名由安卓提供
//     App[funName](data)
//     return
//   }
//   return ''
// }

// //获取app端传值给h5的值
// const getAppData = (key) => {
//   if (isApp) {
//     if (window[key]) {
//       return window[key]
//     } else {
//       console.error(`无法获取app端的参数为"${key}"对应的值`)
//       return ''
//     }
//   }
// }
