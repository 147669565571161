// 1：正式生产环境 // 2：内部测试环境 //3: '预发布环境'
const PROD=1,TEST=2,PRE=3;

export const currentDev =(() => {
    if(typeof GLOBAL_ENV === 'undefined'){
        return PROD
    }
    if('test' === GLOBAL_ENV){
        return TEST
    }
    if('pre' === GLOBAL_ENV){
        return PRE
    }
    return PROD
  })();

export const isTestEnv =  currentDev === TEST
