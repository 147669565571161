import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import { store } from '@/pinia/store'

// 路由信息
const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/index',
    name: 'homeModule',
    component: () => import('../views/homeModule/index.vue'),
    meta: {
      title: '往约',
      isNotAuth: true
    },
  },
  {
    path: '/pages/recruit/index',
    name: 'recruit',
    component: () => import('../views/pages/recruit/Index.vue'),
    meta: {
      title: '招聘合作',
      showAppBtn: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/recruit/masseur',
    name: 'masseur',
    component: () => import('../views/pages/recruit/Masseur.vue'),
    meta: {
      title: '应聘技师',
      showAppBtn: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/recruit/partner',
    name: 'partner',
    component: () => import('../views/pages/recruit/Partner.vue'),
    meta: {
      title: '城市合伙人',
      showAppBtn: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/recruit/storeSurvey',
    name: 'storeSurvey',
    component: () => import('../views/pages/recruit/StoreSurvey.vue'),
    meta: {
      title: '门店入驻',
      showAppBtn: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/friend',
    name: 'inviteFriend',
    component: () => import('../views/pages/invite/friend.vue'),
    meta: {
      title: '邀请好友',
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/friendList',
    name: 'inviteFriendList',
    component: () => import('../views/pages/invite/friendList.vue'),
    meta: {
      title: '邀请好友列表',
      index: 916,
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/technician',
    name: 'inviteTechnician',
    component: () => import('../views/pages/invite/technician.vue'),
    meta: {
      title: '邀请技师',
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/technicianList',
    name: 'inviteTechnicianList',
    component: () => import('../views/pages/invite/technicianList.vue'),
    meta: {
      title: '邀请技师列表',
      isNotAuth: true,
    },
  },
  {
    path: '/pages/invite/poster',
    name: 'poster',
    component: () => import('../views/pages/invite/poster.vue'),
    meta: {
      title: '邀请海报',
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/partner',
    name: 'partnerInviteFriends',
    component: () => import('../views/pages/invite/partner.vue'),
    meta: {
      title: '邀请技师',
      keepAlive: true,
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/techRegister',
    name: 'techRegister',
    component: () => import('../views/pages/invite/techRegister.vue'),
    meta: {
      title: '往约',
      isNotAuth: true
    },
  },
  {
    path: '/pages/invite/inviteCode',
    name: 'inviteCode',
    component: () => import('../views/pages/invite/inviteCode.vue'),
    meta: {
      title: '邀请二维码',
      isNotAuth: true
    },
  },
  {
    path: '/pages/point/index',
    name: 'point',
    component: () => import('../views/pages/point/index.vue'),
    meta: {
      title: '休闲娱乐',
    },
  },
  {
    path: '/pages/point/signIn',
    name: 'signIn',
    component: () => import('../views/pages/point/signIn.vue'),
    meta: {
      title: '签到',
    },
  },
  {
    path: '/pages/point/luckLarge',
    name: 'luckLarge',
    component: () => import('../views/pages/point/luckLarge.vue'),
    meta: {
      title: '幸运大转盘',
    },
  },
  {
    path: '/pages/point/detail',
    name: 'pointDetail',
    component: () => import('../views/pages/point/detail.vue'),
    meta: {
      title: '积分明细',
    },
  },
  {
    path: '/pages/rule/vipcardAgreement',
    name: 'vipcardAgreement',
    component: () => import('../views/pages/rule/vipcardAgreement.vue'),
    meta: {
      title: '会员卡协议',
    },
  },
  {
    path: '/pages/vote/index',
    name: 'vote',
    component: () => import('../views/pages/vote/index.vue'),
    meta: {
      title: '技师调度站',
      isNotAuth: true
    },
  },
  {
    path: '/pages/survey/user',
    name: 'userSurvey',
    component: () => import('../views/pages/survey/user.vue'),
    meta: {
      title: '用户问卷调查',
      isNotAuth: true
    },
  },
  {
    path: '/pages/survey/reBack',
    name: 'reBack',
    component: () => import('../views/pages/survey/reBack.vue'),
    meta: {
      title: '问卷调查',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/growthCenter/index',
    name: 'growthCenter',
    component: () => import('../views/myModule/growthCenter/index.vue'),
    meta: {
      title: '成长中心',
    },
  },
  {
    path: '/myModule/growthCenter/equityDetails',
    name: 'equityDetails',
    component: () => import('../views/myModule/growthCenter/equityDetails.vue'),
    meta: {
      title: '权益详情',
    },
  },
  {
    path: '/myModule/growthCenter/levelDescription',
    name: 'levelDescription',
    component: () => import('../views/myModule/growthCenter/levelDescription.vue'),
    meta: {
      title: '等级说明',
      isNotAuth: true,
    },
  },
  {
    path: '/myModule/growthCenter/myGrowth',
    name: 'myGrowth',
    component: () => import('../views/myModule/growthCenter/myGrowth.vue'),
    meta: {
      title: '我的成长值',
    },
  },
  {
    path: '/myModule/growthCenter/growthDetail',
    name: 'growthDetail',
    component: () => import('../views/myModule/growthCenter/growthDetail.vue'),
    meta: {
      title: '成长值明细',
    },
  },
  {
    path: '/myModule/growthCenter/shareVipCard',
    name: 'shareVipCard',
    component: () => import('../views/myModule/growthCenter/shareVipCard.vue'),
    meta: {
      title: '会员卡分享',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/growthCenter/getVipCard',
    name: 'getVipCard',
    component: () => import('../views/myModule/growthCenter/getVipCard.vue'),
    meta: {
      title: '领取会员卡',
      isNotAuth: true
    },
  },
  {
    path: '/myModule/vipCard/wednesdayMemberDay',
    name: 'wednesdayMemberDay',
    component: () => import('../views/myModule/vipCard/wednesdayMemberDay.vue'),
    meta: {
      title: '周三会员日',
      isNotAuth: true
    },
  },
  {
    path: '/pages/shareNowLocation/index',
    name: 'shareNowLocation',
    component: () => import('../views/pages/shareNowLocation/index.vue'),
    meta: {
      title: '实时定位',
      isNotAuth: true
    },
  },
  {
    path: '/pages/foolDay/index',
    name: 'foolDay',
    component: () => import('../views/pages/foolDay/index.vue'),
    meta: {
      title: '愚人节',
      isNotAuth: true
    },
  },
]

// 导出路由
const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  console.log(234)
  console.log(to)
  const appDataStore = store()
  document.title = to.meta.title
  if (((!localStorage.getItem('expiresAt') || parseInt(localStorage.getItem('expiresAt')) * 1000 - new Date().getTime() <= 10000) && appDataStore.appData.userId && !appDataStore.isShowNewSource)) {
    console.log('router调用刷新接口')
    let tsApi = import('@/tsApi/service.ts')
    console.log(tsApi)
    tsApi.then(async (res) => {
      try {
        await res.resetWyExpiresAt()
      } catch (e) { }
    })
  }
  next()
})
router.onError((err) => {
  console.log(11111, err)
  setTimeout(() => {
    window.location.reload()
  }, 3000);
})

export default router
