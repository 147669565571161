import axios from 'axios'
import { baseUrl } from '@/config/index.js'

let myConfig = {
  baseURL: baseUrl,
}

const _axios = axios.create(myConfig)
// _axios.defaults.headers.common['X-token'] = token
_axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.withCredentials = true
    const obj = {}
    if (config.data) {
      for (const key in config.data) {
        if (config.data[key] != null && config.data[key] != undefined) {
          obj[key] = config.data[key]
        }
      }
    }
    config.data = obj
    // if (config.url == 'v6/order/customer/personas') {
    //   config.baseURL = "https://i.wangyuedaojia.com/api/"
    // }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)
export default _axios
